import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const MiniLogoIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={67} baseHeight={40} {...props}>
    {(width, height) => (
      <svg width={width} height={height} viewBox="0 0 67 40">
        <path d="M47.0206 0.546212C52.115 -0.386548 57.8996 -0.287319 62.5881 2.13389C63.948 2.84834 65.247 3.78111 65.9777 5.11079C66.8707 6.73815 66.8099 8.72275 66.2821 10.489C65.1049 14.5178 61.8981 17.6137 58.813 20.5113C60.5382 18.606 62.0807 16.4627 62.8317 14.0216C63.5827 11.5806 63.4812 8.80214 62.1213 6.63893C60.1525 3.52311 56.1135 2.3522 52.3789 2.07435C44.0775 1.47897 35.8574 4.11849 28.3679 7.65107C20.5536 11.3424 13.1047 16.165 7.84788 22.9126C6.18355 25.0361 4.72219 27.4177 4.25536 30.0572C3.76824 32.6967 4.39744 35.6339 6.40681 37.4796C8.3553 39.2657 10.9736 39.5435 13.4701 40C8.88301 39.6428 1.17026 39.0077 0.0945337 33.5104C-0.291104 31.5059 0.561358 29.4816 1.59649 27.6955C4.58011 22.476 8.9845 18.1099 13.9166 14.5575C22.0962 8.64337 31.7777 3.80095 41.7028 1.51866C43.428 1.1019 45.2141 0.784364 47.0206 0.546212Z" fill="#2165B7"/>
        <path d="M28.5714 31.2729L28.4014 28.6944H22.2449L20.9524 31.2729H17.2449L26.0884 14.7109H31.0884L32.381 31.2729H28.5714ZM27.7211 18.0167L23.6054 25.9506H28.2653L27.7211 18.0167Z" fill="#2165B7"/>
        <path d="M35.3741 31.2729L36.0884 28.0333H45.1701C45.4762 28.0333 45.6803 27.9671 45.8503 27.868C45.9864 27.7688 46.0884 27.5374 46.1565 27.2399L46.6667 24.9258C46.6667 24.8597 46.7007 24.7605 46.7007 24.6614C46.7007 24.3638 46.4966 24.2316 46.0544 24.2316H39.3197C38.6394 24.2316 38.1292 24.0994 37.8231 23.8018C37.517 23.5043 37.3469 23.1076 37.3469 22.5787C37.3469 22.3804 37.3809 22.149 37.415 21.8845L38.3333 17.587C38.5374 16.5952 38.9116 15.868 39.4558 15.4052C39.966 14.9423 40.7823 14.7109 41.9048 14.7109H52.517L51.8367 17.8514H42.7891C42.517 17.8514 42.3129 17.9175 42.2109 18.0167C42.1088 18.1159 42.0068 18.3473 41.9388 18.6448L41.4626 20.8266C41.4626 20.8928 41.4286 20.9589 41.4286 20.9919C41.4286 21.025 41.4286 21.0911 41.4286 21.1242C41.4286 21.3556 41.5986 21.4878 41.9728 21.4878H48.8095C49.5238 21.4878 50.034 21.62 50.3401 21.9176C50.6463 22.2151 50.8163 22.6118 50.8163 23.1407C50.8163 23.339 50.7823 23.5704 50.7483 23.8349L49.7619 28.3638C49.5578 29.3556 49.1837 30.0828 48.6395 30.5456C48.1292 31.0085 47.3129 31.2399 46.2245 31.2399L35.3741 31.2729Z" fill="#494949"/>
      </svg>
    )}
  </Icon>
);
