import i18next from "@/i18n/config";

export enum ROLE {
  CUSTOMER = 'customer',
  EMPLOYER = 'employer',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super-admin',
}

export const ROLE_VALUES = Object.values(ROLE);

export const ROLES = ROLE_VALUES.map((item) => ({
  text: i18next.t(`common.roles.${item}`),
  value: item,
}));
