import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FullLayout from "@/layouts/FullLayout";
import ConfigurationForm from "@/pages/Configuration/ConfigurationForm";
import {SettingsModel} from "@/resources/models";
import {SettingsService, ToastService} from "@/services";

const Configuration = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<SettingsModel>();

  useEffect(() => {
    SettingsService.get().then((data) => {
      setSettings(data);
    }).catch((e) => {
      ToastService.showHttpError(e, t('configuration.loadingFailedMessage'));
    });
  }, [t]);

  return (
    <FullLayout>
      <h1>{t('configuration.pageTitle')}</h1>

      {settings && (
        <div className="card mt-6">
          <ConfigurationForm settings={settings} onSubmit={setSettings} />
        </div>
      )}
    </FullLayout>
  );
};
export default Configuration;
