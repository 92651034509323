import React, {FC, useEffect} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {TextInput} from "@/components";
import {SettingsModel} from "@/resources/models";
import {SettingsService, ToastService} from "@/services";

export interface IConfigurationFormProps {
  settings: SettingsModel;
  onSubmit(settings: SettingsModel): void;
}

export type IFormData = Omit<SettingsModel, 'id'>;

const ConfigurationForm: FC<IConfigurationFormProps> = ({
  settings,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const validationError = {
    required: t('common.validation.required'),
  };

  const formSchema = Yup.object().shape({
    maxRepairDays: Yup.number().required(validationError.required),
  });

  const formik = useFormik<IFormData>({
    initialValues: settings,
    validationSchema: formSchema,
    onSubmit(values) {
      SettingsService.patch(values).then((res) => {
        ToastService.success(t('common.changesSavedMessage'));
        onSubmit(res);
      }).catch((err) => {
        ToastService.showHttpError(err, t('common.changesSaveFailedMessage'));
      });
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        maxRepairDays: settings.maxRepairDays,
      },
    });
  }, [settings]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6">
        <TextInput
          className="col-span-12 md:col-span-6"
          formik={formik}
          name="maxRepairDays"
          type="number"
          fullWidth
          label={t('configuration.maxRepairDays')}
        />
      </div>

      <div className="flex justify-end mt-6">
        <button
          className="btn btn-blue"
          type="submit"
        >
          {t('common.save')}
        </button>
      </div>
    </form>
  );
}

export default ConfigurationForm;
