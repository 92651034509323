import i18next from "@/i18n/config";

export enum PAYMENT_STATUS {
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
}

export const PAYMENT_STATUS_VALUES = Object.values(PAYMENT_STATUS);

export const PAYMENT_STATUSES = PAYMENT_STATUS_VALUES.map((item) => ({
  text: i18next.t(`common.paymentStatuses.${item}`),
  value: item,
}));
