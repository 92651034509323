import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { CustomDrawer } from "@/components/CustomDrawer";
import { ROUTES } from "@/constants";
import {
  AccountCircleIcon,
  AccountCommentIcon,
  BuildIcon,
  InboxIcon,
  RepairIcon,
  SettingsOutlineIcon,
  WorktableIcon
} from "@/icons";
import { ROLE } from "@/resources/enums";
import { useAppSelector } from "@/redux/hooks";

export interface ISidebarProps {
  opened: boolean;
  onToggle(): void;
}

const Sidebar: FC<ISidebarProps> = ({
  opened,
  onToggle,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth);

  const menuItems = [
    {
      name: t('repairs.pageTitle'),
      path: ROUTES.REPAIRS.PREFIX,
      icon: RepairIcon,
    },
    {
      name: t('customers.pageTitle'),
      path: ROUTES.CUSTOMERS.PREFIX,
      icon: AccountCommentIcon,
    },
    'separator',
    {
      name: t('bins.pageTitle'),
      path: ROUTES.BINS.PREFIX,
      icon: InboxIcon,
    },
    {
      name: t('components.pageTitle'),
      path: ROUTES.COMPONENTS.PREFIX,
      icon: BuildIcon,
    },
    {
      name: t('worktables.pageTitle'),
      path: ROUTES.WORKTABLES.PREFIX,
      icon: WorktableIcon,
    },
    {
      name: t('employees.pageTitle'),
      path: ROUTES.EMPLOYEES.PREFIX,
      icon: AccountCircleIcon,
      role: [ROLE.ADMIN, ROLE.SUPER_ADMIN],
    },
    {
      name: t('configuration.pageTitle'),
      path: ROUTES.CONFIGURATION.PREFIX,
      icon: SettingsOutlineIcon,
      role: [ROLE.ADMIN, ROLE.SUPER_ADMIN],
    },
  ];
  
  const activeMenu = menuItems.find((item) => typeof item === 'object' && location.pathname.startsWith(item.path));

  return (
    <CustomDrawer isOpen={opened} size="sm" onClose={onToggle}>
      <div className="mt-9">
        {menuItems.map((item, i) => {
          if (item === 'separator') {
            return (
              <hr key={i} className="bg-blue-200 mx-10 my-10" />
            );
          }

          if (typeof item === 'object' && user!.hasPermission(item.role)) {
            return (
              <Link
                key={i}
                className={classnames(
                  'flex items-center hover:text-blue-900 font-semibold px-10 py-5 transition-all',
                  item === activeMenu ? 'text-blue-900' : 'text-blue-400',
                )}
                to={item.path}
              >
                <item.icon className="mr-4" />
                <span>{item.name}</span>
              </Link>
            );
          }

          return null;
        })}
      </div>
    </CustomDrawer>
  );
}

export default Sidebar;
