export class SettingsModel {
  id: string;
  maxRepairDays: number;

  constructor(init?: Partial<SettingsModel>) {
    const data = {
      id: '',
      maxRepairDays: 0,
      ...init,
    };

    this.id = data.id;
    this.maxRepairDays = data.maxRepairDays;
  }
}
