import i18next from "@/i18n/config";

export enum DELIVERY_METHOD {
  COUNTER = 'COUNTER',
  PICKUP = 'PICKUP',
  SHIPPING = 'SHIPPING',
  WORKSHOP = 'WORKSHOP',
}

export const DELIVERY_METHOD_VALUES = Object.values(DELIVERY_METHOD);

export const DELIVERY_METHODS = [
  {
    text: i18next.t(`common.deliveryMethods.${DELIVERY_METHOD.COUNTER}`),
    value: DELIVERY_METHOD.COUNTER,
    color: 'success',
  },
  {
    text: i18next.t(`common.deliveryMethods.${DELIVERY_METHOD.PICKUP}`),
    value: DELIVERY_METHOD.PICKUP,
    color: 'secondary',
  },
  {
    text: i18next.t(`common.deliveryMethods.${DELIVERY_METHOD.SHIPPING}`),
    value: DELIVERY_METHOD.SHIPPING,
    color: 'secondary',
  },
  {
    text: i18next.t(`common.deliveryMethods.${DELIVERY_METHOD.WORKSHOP}`),
    value: DELIVERY_METHOD.WORKSHOP,
    color: 'secondary',
  },
];
