import axios from "axios";
import { toast, ToastOptions } from "react-toastify";

export class ToastService {

  static info(message: string | JSX.Element) {
    ToastService.show(message, { type: 'info' });
  }

  static success(message: string | JSX.Element | null) {
    ToastService.show(message || 'Success', { type: 'success' });
  }

  static warning(message: string | JSX.Element) {
    ToastService.show(message, { type: 'warning' });
  }

  static error(message: string | JSX.Element | null) {
    ToastService.show(message || 'Error', { type: 'error' });
  }

  static show(message: string | JSX.Element, options: ToastOptions) {
    toast(message, {
      ...options,
    });
  }

  static showHttpError(err: Error, defaultMessage: string | JSX.Element | null) {
    if (!axios.isAxiosError(err)) {
      ToastService.error(err?.message || defaultMessage || 'Error');
      return;
    }

    const response = err?.response;
    const data = response?.data;

    if (!response) {
      ToastService.error('Network Error!');
      return;
    }
    if (!data) {
      ToastService.error(`${response.status} error - ${response.statusText}`);
      return;
    }
    if (data.message) {
      ToastService.error(Array.isArray(data.message) ? data.message.join('\n') : data.message.toString());
      return;
    }

    ToastService.error(defaultMessage || 'Error');
  }
}
