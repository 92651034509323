import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataTable, IDataTableColumn, IPaginationModel, ISortModel } from "@/components";
import FullLayout from "@/layouts/FullLayout";
import EditBinPanel from "@/pages/Bins/EditBinPanel";
import { BIN_POSITIONS } from "@/resources/enums";
import { BinModel, RepairModel } from "@/resources/models";
import { BinService, ToastService } from "@/services";
import { formatDate, getSortQueryString } from "@/utils/helpers";

export interface IFilter {
  search: string;
  sort: ISortModel[];
  pagination: IPaginationModel;
}

const Bins = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<IFilter>({
    search: '',
    sort: [{ field: 'name', dir: 'ASC' }],
    pagination: {
      page: 0,
      perPage: 20,
    },
  });
  const [totalCount, setTotalCount] = useState(0);
  const [bins, setBins] = useState<BinModel[]>([]);
  const [updatingBin, setUpdatingBin] = useState<BinModel>();

  const tableColumns: IDataTableColumn[] = [
    {
      title: t('common.name') || 'Name',
      field: 'name',
      sortable: true,
      render(data: BinModel) {
        return (
          <span className="text-link" onClick={() => setUpdatingBin(data)}>
            {data.name}
          </span>
        );
      },
    },
    {
      title: t('common.category') || 'Category',
      field: 'category',
      sortable: true,
    },
    {
      title: t('common.volume') || 'Volume',
      field: 'content',
      sortable: true,
      render(data: BinModel) {
        return `${data.content} ltr`;
      },
    },
    {
      title: t('common.position') || 'Position',
      field: 'position',
      sortable: true,
      render(data: BinModel) {
        const position = BIN_POSITIONS.find((item) => item.value === data.position);
        return position?.text || data.position;
      },
    },
    {
      title: t('common.repairNumber') || 'Repair number',
      field: 'orderId',
      sortable: true,
      render(data: BinModel) {
        if (!data.orderId) {
          return null;
        }
        return (
          <Link className="text-link" to={`/repairs/${data.orderId}`}>
            {data.orderId}
          </Link>
        );
      },
    },
    {
      title: t('common.dateCreated') || 'Date created',
      field: 'createdAt',
      cellClass: 'text-blue-400',
      sortable: true,
      getText(data: RepairModel) {
        return formatDate(data.createdAt, 'full').toLowerCase();
      },
    },
    {
      field: '',
      maxWidth: '10rem',
      align: 'right',
      cellClass: 'items-center whitespace-nowrap !py-3 gap-2',
      render(data: BinModel) {
        return (
          <>
            <button className="icon-btn btn-sm" onClick={() => setUpdatingBin(data)}>
              <i className="fa fa-edit" />
            </button>
            <button className="icon-btn btn-sm" onClick={() => onDeleteBin(data)}>
              <i className="fa fa-trash" />
            </button>
          </>
        )
      }
    },
  ];

  const loadTableData = useCallback(() => {
    BinService.search({
      search: filter.search,
      skip: filter.pagination.page * filter.pagination.perPage,
      limit: filter.pagination.perPage,
      sort: getSortQueryString(filter.sort),
    }).then((res) => {
      setBins(res.data);
      setTotalCount(res.totalCount);
    });
  }, [filter]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const onFilterChange = useCallback((field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
      ...(field === 'search' && ({
        pagination: {
          ...prev.pagination,
          page: 0,
        },
      })),
    }));
  }, []);

  const onCreate = () => {
    setUpdatingBin(new BinModel());
  };

  const onDeleteBin = (bin: BinModel) => {
    if (!window.confirm(t('bins.deletionConfirmMessage') || 'Are your sure?')) {
      return;
    }
    BinService.remove(bin.id).then(() => {
      ToastService.success(t('bins.binDeletedMessage'));
      loadTableData();
    }).catch((err) => {
      ToastService.showHttpError(err, t('common.operationFailedMessage'));
    });
  };

  const onCloseUpdateBinPanel = (result?: BinModel) => {
    if (result) {
      loadTableData();
    }
    setUpdatingBin(undefined);
  };

  return (
    <FullLayout onSearchChange={onFilterChange}>
      <div className="flex items-center justify-between flex-wrap gap-2">
        <h1 className="mr-8">{t('bins.pageTitle')}</h1>
        <button className="btn btn-blue" onClick={onCreate}>
          {t('bins.createBin')}
        </button>
      </div>
      <DataTable
        className="mt-4"
        theme="card-row"
        columns={tableColumns}
        data={bins}
        serverSide
        sortModel={filter.sort}
        pagination="auto"
        paginationModel={filter.pagination}
        totalCount={totalCount}
        onSort={(model) => onFilterChange('sort', model)}
        onPaginate={(model) => onFilterChange('pagination', model)}
      />

      <EditBinPanel
        opened={Boolean(updatingBin)}
        bin={updatingBin}
        onClose={onCloseUpdateBinPanel}
      />
    </FullLayout>
  );
};
export default Bins;
