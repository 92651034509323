import React, {FC, ReactNode, useCallback, useState} from "react";
import LayoutHeader from "@/layouts/FullLayout/Header";
import Sidebar from "@/layouts/FullLayout/Sidebar";

export interface IFullLayoutProps {
  onSearchChange?: (field: 'search', value: string) => void;
  header?: ReactNode;
  children: ReactNode;
}

const FullLayout: FC<IFullLayoutProps> = ({
  onSearchChange,
  header,
  children,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const onToggleSidebar = useCallback(() => {
    setSidebarOpened((prev) => !prev);
  }, []);

  return (
    <div className="w-full min-h-screen bg-blue-100 text-blue-900 relative">
      <LayoutHeader
        onToggleSidebar={onToggleSidebar}
        onSearchChange={onSearchChange}
      >
        {header}
      </LayoutHeader>

      <Sidebar opened={sidebarOpened} onToggle={onToggleSidebar} />

      <div className="px-4 py-6 sm:px-10 sm:py-12">
        {children}
      </div>
    </div>
  );
};

export default FullLayout;
