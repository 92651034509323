import { createSlice } from "@reduxjs/toolkit";

export interface ISpinnerState {
	loadingCount: number;
}

const initialState: ISpinnerState = {
	loadingCount: 0,
};

export const spinnerSlice = createSlice({
	name: 'spinner',
	initialState,
	reducers: {
		startLoading: (state) => {
			state.loadingCount ++;
		},
		finishLoading: (state) => {
			state.loadingCount = Math.max(state.loadingCount - 1, 0);
		},
	},
});

export const { startLoading, finishLoading } = spinnerSlice.actions;

export default spinnerSlice.reducer;
