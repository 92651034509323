import React, {FC, useEffect} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {CustomDrawer, Select, TextInput} from "@/components";
import {ROLE, ROLES} from "@/resources/enums";
import {EmployerModel} from "@/resources/models";
import {EmployerService, ToastService} from "@/services";

export interface IEditEmployerPanelProps {
  opened: boolean;
  employer?: EmployerModel;
  onClose(result?: EmployerModel): void;
}

export interface IFormData {
  firstName: string;
  lastName: string;
  role: ROLE;
}

const EditEmployerPanel: FC<IEditEmployerPanelProps> = ({
  opened,
  employer,
  onClose,
}) => {
  const { t } = useTranslation();

  const roleOptions = [ROLE.EMPLOYER, ROLE.ADMIN].map((role) => {
    return ROLES.find((item) => item.value === role)
  });

  const validationError = {
    required: t('common.validation.required'),
  };

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required(validationError.required),
    lastName: Yup.string().required(validationError.required),
    role: Yup.string().required(validationError.required),
  });

  const isCreate = !employer?.id;

  const formik = useFormik<IFormData>({
    initialValues: {
      firstName: employer?.firstName || '',
      lastName: employer?.lastName || '',
      role: employer?.role || ROLE.EMPLOYER,
    },
    validationSchema: formSchema,
    onSubmit(values) {
      const formData = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        role: values.role,
      };
      if (isCreate) {
        EmployerService.create(formData).then((res) => {
          ToastService.success(t('employees.employerCreatedMessage'));
          onClose(res);
        }).catch((err) => {
          ToastService.showHttpError(err, t('employees.creationFailedMessage'));
        });
      } else {
        EmployerService.update(employer!.id, formData).then((res) => {
          ToastService.success(t('common.changesSavedMessage'));
          onClose(res);
        }).catch((err) => {
          ToastService.showHttpError(err, t('common.changesSaveFailedMessage'));
        });
      }
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        firstName: employer?.firstName || '',
        lastName: employer?.lastName || '',
        role: employer?.role || ROLE.EMPLOYER,
      },
    });
  }, [employer]);

  const fullName = [formik.values.firstName.trim(), formik.values.lastName.trim()]
    .filter((item) => !!item)
    .join('.')
    .toLowerCase();

  return (
    <CustomDrawer
      isOpen={opened}
      title={t(isCreate ? 'employees.createEmployer' : 'employees.updateEmployer') || ''}
      position="end"
      contentClass="p-8"
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col items-end gap-6">
          <TextInput
            formik={formik}
            name="firstName"
            fullWidth
            label={t('common.firstName')}
            size="small"
          />

          <TextInput
            formik={formik}
            name="lastName"
            fullWidth
            label={t('common.lastName')}
            size="small"
          />

          <TextInput
            name="fullName"
            fullWidth
            label={t('common.fullName')}
            value={fullName}
            size="small"
            disabled
          />

          <Select
            formik={formik}
            name="role"
            fullWidth
            label={t('common.role')}
            size="small"
            options={roleOptions}
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.value}
          />

          <button
            className="btn btn-blue"
            type="submit"
          >
            {t(isCreate ? 'common.create' : 'common.save')}
          </button>
        </div>
      </form>
    </CustomDrawer>
  );
}

export default EditEmployerPanel;
