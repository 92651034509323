import i18next, {InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './translations/en.json';
import nlTranslation from './translations/nl.json';

export const resources = {
  en: {
    translation: enTranslation,
  },
  nl: {
    translation: nlTranslation,
  },
};

i18next
  // Enable automatic language detection
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Standard language used
    fallbackLng: 'nl',
    debug: true,
    resources,
    // Detects and caches a cookie from the language provided
    detection: {
      order: ['querystring', 'localStorage'],
      cache: ['localStorage'],
      lookupQuerystring: 'lang'
    },
    interpolation: {
      escapeValue: false
    }
  } as InitOptions);

export default i18next;
