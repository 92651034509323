import {Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {createTheme, ThemeProvider} from "@mui/material";
import {ROUTES} from "@/constants";
import {SpinnerContainer} from "@/components";
import AuthRouting from "@/pages/Auth/routing";
import ResetPassword from "@/pages/Auth/ResetPassword";
import RepairsRouting from "@/pages/Repairs/routing";
import CustomersRouting from "@/pages/Customers/routing";
import EmployersRouting from "@/pages/Employers/routing";
import BinsRouting from "@/pages/Bins/routing";
import ConfigurationRouting from "@/pages/Configuration/routing";
import {AuthProvider} from "@/providers";
import {useAppSelector} from "@/redux/hooks";
import {ROLE} from "@/resources/enums";
import {dateTimelocaleSetup} from "./utils/helpers";

// Change momentjs global locale
dateTimelocaleSetup(); 

function App() {
  const { user } = useAppSelector((state) => state.auth);

  const theme = createTheme({
    typography: {
      fontFamily: 'Archivo, Roboto, sans-serif',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        {!user ? (
          <Routes>
            <Route path="*" element={<AuthRouting />} />
          </Routes>
        ) : ([ROLE.EMPLOYER, ROLE.ADMIN].includes(user.role) && user.isFirstLogin) ? (
          <Routes>
            <Route path={ROUTES.AUTH.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path="*" element={<Navigate to={ROUTES.AUTH.RESET_PASSWORD} />} />
          </Routes>
        ) : (
          <Routes>
            <Route path={`${ROUTES.REPAIRS.PREFIX}/*`} element={<RepairsRouting />} />
            <Route path={`${ROUTES.CUSTOMERS.PREFIX}/*`} element={<CustomersRouting />} />
            <Route path={`${ROUTES.BINS.PREFIX}/*`} element={<BinsRouting />} />
            {user.hasPermission([ROLE.ADMIN, ROLE.SUPER_ADMIN]) && (
              <Route path={`${ROUTES.EMPLOYEES.PREFIX}/*`} element={<EmployersRouting />} />
            )}
            {user.hasPermission([ROLE.ADMIN, ROLE.SUPER_ADMIN]) && (
              <Route path={`${ROUTES.CONFIGURATION.PREFIX}/*`} element={<ConfigurationRouting />} />
            )}
            <Route path="*" element={<Navigate to={ROUTES.REPAIRS.PREFIX} />} />
          </Routes>
        )}
      </AuthProvider>

      <ToastContainer />
      <SpinnerContainer />
    </ThemeProvider>
  );
}

export default App;
