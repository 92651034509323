import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const AccountCommentIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M20.3111 2H6.00001C4.86534 2 3.95557 2.9 3.95557 4V18C3.95557 19.1 4.86534 20 6.00001 20H10.0889L13.1556 23L16.2222 20H20.3111C21.4356 20 22.3556 19.1 22.3556 18V4C22.3556 2.9 21.4356 2 20.3111 2ZM20.3111 18H15.3738L14.7707 18.59L13.1556 20.17L11.5302 18.58L10.9373 18H6.00001V4H20.3111V18ZM13.1556 11C14.8422 11 16.2222 9.65 16.2222 8C16.2222 6.35 14.8422 5 13.1556 5C11.4689 5 10.0889 6.35 10.0889 8C10.0889 9.65 11.4689 11 13.1556 11ZM13.1556 7C13.7178 7 14.1778 7.45 14.1778 8C14.1778 8.55 13.7178 9 13.1556 9C12.5933 9 12.1333 8.55 12.1333 8C12.1333 7.45 12.5933 7 13.1556 7ZM19.2889 15.58C19.2889 13.08 15.2307 12 13.1556 12C11.0805 12 7.02223 13.08 7.02223 15.58V17H19.2889V15.58ZM9.55734 15C10.3138 14.49 11.8369 14 13.1556 14C14.4742 14 15.9973 14.49 16.7538 15H9.55734Z"
        />
      </svg>
    )}
  </Icon>
);
