import {HttpService} from "./http.service";
import {CreateEmployerRequestDto, SearchEmployersRequestDto, UpdateEmployerRequestDto} from "@/resources/dtos";
import {EmployerModel} from "@/resources/models/employer.model";
import {formatPaginatedResult, trimObject} from "@/utils/helpers";

export class EmployerService {

  static search(data: SearchEmployersRequestDto, showSpinner = true) {
    return HttpService.get('/employers', trimObject(data), showSpinner)
      .then((res) => formatPaginatedResult<EmployerModel>(EmployerModel, res));
  }

  static find(id: number, showSpinner = true) {
    return HttpService.get(`/employers/${id}`, {}, showSpinner)
      .then((res) => new EmployerModel(res));
  }

  static create(data: CreateEmployerRequestDto, showSpinner = true) {
    return HttpService.post('/employers', data, showSpinner)
      .then((res) => new EmployerModel(res));
  }

  static update(id: number, data: UpdateEmployerRequestDto, showSpinner = true) {
    return HttpService.put(`/employers/${id}`, data, showSpinner)
      .then((res) => new EmployerModel(res));
  }

  static remove(id: number, showSpinner = true) {
    return HttpService.delete(`/employers/${id}`, {}, showSpinner);
  }
}
