import {HttpService} from "./http.service";
import {CreateBinRequestDto, SearchBinsRequestDto, UpdateBinRequestDto} from "@/resources/dtos";
import {DELIVERY_METHOD} from "@/resources/enums";
import {BinModel} from "@/resources/models/bin.model";
import {formatPaginatedResult, trimObject} from "@/utils/helpers";

export class BinService {

  static search(data: SearchBinsRequestDto = {}, showSpinner = true) {
    return HttpService.get('/bins', trimObject(data), showSpinner)
      .then((res) => formatPaginatedResult<BinModel>(BinModel, res));
  }

  static getAvailableBinsByContent(deliveryMethod: DELIVERY_METHOD, showSpinner = true): Promise<BinModel[]> {
    return HttpService.get('/bins/available-by-content', { deliveryMethod }, showSpinner)
      .then((res) => res.map((item: BinModel) => new BinModel(item)));
  }

  static find(id: number, showSpinner = true) {
    return HttpService.get(`/bins/${id}`, {}, showSpinner)
      .then((res) => new BinModel(res));
  }

  static create(data: CreateBinRequestDto, showSpinner = true) {
    return HttpService.post('/bins', data, showSpinner)
      .then((res) => new BinModel(res));
  }

  static update(id: number, data: UpdateBinRequestDto, showSpinner = true) {
    return HttpService.put(`/bins/${id}`, data, showSpinner)
      .then((res) => new BinModel(res));
  }

  static remove(id: number, showSpinner = true) {
    return HttpService.delete(`/bins/${id}`, {}, showSpinner);
  }
}
