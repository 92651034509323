import {PaginatedResultDto} from "@/resources/dtos";
import {ISortModel} from "@/components";

export const formatPaginatedResult = <T>(Model: any, data: PaginatedResultDto<any>): PaginatedResultDto<T> => {
  return {
    ...data,
    data: data.data.map((item) => new Model(item)),
  };
};

export const getSortQueryString = (sortModel: ISortModel[] = []) => {
  return sortModel.map((item) => {
    const dir = item.dir === 'ASC' ? '' : '-';
    return item.field.split(',').map((field) => `${dir}${field}`);
  }).flat().join();
};
