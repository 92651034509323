import {ReactEventHandler} from "react";

export interface IIconProps {
  className?: string;
  size?: number;
  color?: string | string[];
  onClick?: ReactEventHandler;
}

export const ICON_DEFAULT_COLORS: {
  [name: string]: string;
} = {
  white: '#FFF',
  black: '#000',
  gray: '#6E7E8E',
  blue: '#2165B7',
  'blue-dark': '#193451',
};
