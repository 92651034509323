import {FC, useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";
import classnames from "classnames";
import {SearchIcon} from "@/icons";

export interface ISearchBoxProps {
  className?: string;
  opened?: boolean;
  placeholder?: string;
  onChange?: (field: 'search', value: string) => void;
}

export const SearchBox: FC<ISearchBoxProps> = ({
  className,
  opened = true,
  placeholder,
  onChange,
}) => {
  const [expanded, setExpanded] = useState(opened);

  useEffect(() => {
    setExpanded(opened);
  }, [opened]);

  const onToggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const onFieldChange = useMemo(() => {
    if (!onChange) {
      return () => {};
    }
    return debounce(onChange, 500);
  }, [onChange]);

  return (
    <div
      className={classnames(
        'h-12 grow flex-center border border-secondary overflow-hidden transition-all ml-auto',
        expanded ? 'w-full bg-blue-100 rounded' : 'w-12 bg-white rounded-3xl',
        className,
      )}
    >
      <input
        className={classnames(
          'w-0 h-full grow bg-transparent text-blue-900 border-0 transition-all',
          expanded ? 'px-5' : 'w-0 opacity-0 p-0',
        )}
        placeholder={placeholder}
        onChange={(e) => onFieldChange('search', e.target.value)}
      />
      <SearchIcon
        className={classnames(
          'h-full shrink-0 flex-center text-blue cursor-pointer',
          expanded ? 'px-4' : 'w-full',
        )}
        onClick={onToggleExpand}
      />
    </div>
  );
};
