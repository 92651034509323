import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const PlusIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M5 11L11 11L11 5L13 5L13 11L19 11L19 13L13 13L13 19L11 19L11 13L5 13L5 11Z"
        />
      </svg>
    )}
  </Icon>
);
