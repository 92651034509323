import {DELIVERY_METHOD, ORDER_TYPE, PAYMENT_STATUS, REPAIR_STATUS} from "../enums";
import {CustomerModel} from "@/resources/models/customer.model";
import {OrderProductModel} from "@/resources/models/order-product.model";

export class RepairModel {
  id: string;
  customer?: CustomerModel;
  deliveryMethod?: DELIVERY_METHOD;
  urgent: boolean;
  paymentStatus: PAYMENT_STATUS;
  proforma: boolean;
  internal: boolean;
  brand: string;
  type?: ORDER_TYPE;
  guarantee: boolean;
  comment: string;
  products: OrderProductModel[];
  status: REPAIR_STATUS;
  startUnpackingAt: string;
  endUnpackingAt: string;
  startBookingAt: string;
  endBookingAt: string;
  createdAt: string;
  updatedAt: string;

  overdueDays?: number;

  constructor(init?: Partial<RepairModel>) {
    const data = {
      id: '',
      customer: null,
      urgent: false,
      paymentStatus: PAYMENT_STATUS.NOT_PAID,
      proforma: false,
      internal: false,
      brand: '',
      guarantee: false,
      comment: '',
      products: [],
      status: REPAIR_STATUS.READY,
      startUnpackingAt: '',
      endUnpackingAt: '',
      startBookingAt: '',
      endBookingAt: '',
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    if (data.customer) {
      this.customer = new CustomerModel(data.customer);
    }
    this.deliveryMethod = data.deliveryMethod;
    this.urgent = data.urgent;
    this.paymentStatus = data.paymentStatus;
    this.proforma = data.proforma;
    this.internal = data.internal;
    this.brand = data.brand;
    this.type = data.type;
    this.guarantee = data.guarantee;
    this.comment = data.comment || '';
    this.products = (data.products || []).map((item) => new OrderProductModel(item));
    this.status = data.status;
    this.startUnpackingAt = data.startUnpackingAt;
    this.endUnpackingAt = data.endUnpackingAt;
    this.startBookingAt = data.startBookingAt;
    this.endBookingAt = data.endBookingAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;

    this.overdueDays = data.overdueDays;
  }
}
