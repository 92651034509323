import {RepairModel} from "@/resources/models/repair.model";
import {BinModel} from "@/resources/models/bin.model";

export interface ProductFormData {
  name: string;
  image?: string;
  price: number;
  count: number;
}

export class OrderProductModel {
  id: number;
  orderId: string;
  binId?: number;
  orderBinId?: string;
  name: string;
  image?: string;
  price: number;
  count: number;
  createdAt: string;
  updatedAt: string;
  order?: RepairModel;
  bin?: BinModel;

  constructor(init?: Partial<OrderProductModel>) {
    const data = {
      id: 0,
      orderId: '',
      name: '',
      price: 0,
      count: 0,
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    this.orderId = data.orderId;
    this.binId = data.binId;
    this.orderBinId = data.orderBinId;
    this.name = data.name;
    this.image = data.image;
    this.price = data.price;
    this.count = data.count;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;

    if (data.order) {
      this.order = new RepairModel(data.order);
    }
    if (data.bin) {
      this.bin = new BinModel(data.bin);
    }
  }
}
