import { FC, useState } from "react";
import { MoreVertIcon, SortIcon } from "@/icons";
import { IFilter } from "@/pages/Repairs/Home";
import FilterMenu from "@/pages/Repairs/Home/FilterMenu";
import SortPanel from "@/pages/Repairs/Home/SortPanel";
import { DeliveryMethodSelect, OrderStatusSelect } from "@/sections";

export interface IFilterProps {
  filter: IFilter;
  onChange(updates: Partial<IFilter>): void;
}

const Filter: FC<IFilterProps> = ({
  filter,
  onChange,
}) => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const onFilterChange = (field: keyof IFilter, value: any) => {
    onChange({
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex items-center justify-between flex-wrap max-sm:w-full gap-2">
        <div className="flex items-center flex-wrap gap-2">
          <DeliveryMethodSelect
            value={filter.deliveryMethod}
            onChange={(value) => onFilterChange('deliveryMethod', value)}
          />

          <OrderStatusSelect
            value={filter.status}
            onChange={(value) => onFilterChange('status', value)}
          />
        </div>

        <div className="flex items-center flex-wrap gap-2 ml-auto">
          <FilterMenu filter={filter.filter} onChange={(value) => onFilterChange('filter', value)} />

          <button className="icon-btn" onClick={() => setDrawerOpened(true)}>
            <SortIcon />
          </button>

          <button className="icon-btn" onClick={() => setDrawerOpened(true)}>
            <MoreVertIcon />
          </button>
        </div>
      </div>

      <SortPanel
        opened={drawerOpened}
        filter={filter}
        onChange={onChange}
        onClose={() => setDrawerOpened(false)}
      />
    </>
  );
};

export default Filter;
