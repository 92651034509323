import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, InputBase, MenuItem, Select} from "@mui/material";
import classnames from "classnames";
import {CountOrdersRequestDto} from "@/resources/dtos";
import {REPAIR_STATUS, REPAIR_STATUS_OPTIONS} from "@/resources/enums";
import {RepairService} from "@/services";

export interface IOrderStatusSelectProps {
  className?: string;
  value?: REPAIR_STATUS[];
  query?: Partial<CountOrdersRequestDto>;
  onChange(value: REPAIR_STATUS[]): void;
}

export const OrderStatusSelect: FC<IOrderStatusSelectProps> = ({
  className,
  value = [],
  query,
  onChange,
}) => {
  const { t } = useTranslation();
  const [orderStatusOptions, setOrderStatusOptions] = useState<{
    text: string;
    value: REPAIR_STATUS;
    count: number;
  }[]>([]);

  useEffect(() => {
    RepairService.count({ ...query, field: 'status' }).then((data) => {
      const options = REPAIR_STATUS_OPTIONS.map((option) => {
        const result = data.find((item: any) => item.status === option.value);
        return {
          text: option.text,
          value: option.value,
          count: result?.count || 0,
        };
      });
      setOrderStatusOptions(options);
    });
  }, [query]);

  return (
    <Select
      className={classnames('filter-select', className)}
      multiple
      value={value}
      onChange={(e) => onChange(e.target.value as REPAIR_STATUS[])}
      input={<InputBase />}
      displayEmpty
      renderValue={(selected) => {
        if (!selected?.length) {
          return t('repairs.tableColumns.status');
        }
        const firstOption = orderStatusOptions.find((item) => item.value === selected[0]);
        return `${firstOption?.text || selected[0]}${selected.length > 1 ? ` +${selected.length - 1}` : ''}`;
      }}
      MenuProps={{
        className: 'filter-select-menu',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        transformOrigin: { horizontal: 'left', vertical: 'top' },
      }}
    >
      {orderStatusOptions.map((option) => (
        <MenuItem key={option.value} className="p-0" value={option.value}>
          <Checkbox className="!ml-2" checked={value.includes(option.value)} />
          <div className="flex items-center justify-between grow">
            <span className="text-blue-900">{option.text}</span>
            <span className="text-blue-400 ml-4 mr-6">{option.count}</span>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};
