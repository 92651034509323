import React, {FC, ReactNode} from "react";
import classnames from "classnames";

export interface IAuthLayoutProps {
  className?: string;
  children: ReactNode;
}

const AuthLayout: FC<IAuthLayoutProps> = ({
  className,
  children,
}) => (
  <div className="w-full h-screen flex-center bg-blue-100 text-blue-900 p-4">
    <div className={classnames('card w-100 max-w-[90%] shadow !py-4', className)}>
      {children}
    </div>
  </div>
);

export default AuthLayout;
