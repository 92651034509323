import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataTable, IDataTableColumn, ISortModel} from "@/components";
import {REPAIR_STATUS} from "@/resources/enums";
import {OrderProductModel, RepairModel} from "@/resources/models";
import {formatCurrency, getRepairStatusText} from "@/utils/helpers";

interface IFilter {
  sort: ISortModel[];
}

export interface IRepairDetailTableProps {
  className?: string;
  repair: RepairModel;
}

const RepairDetailTable: FC<IRepairDetailTableProps> = ({
  className,
  repair,
}) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<IFilter>({
    sort: [],
  });

  const tableColumns: IDataTableColumn[] = [
    {
      title: t('common.bin') || 'Bin',
      field: 'binId',
      sortable: true,
      getText(data: OrderProductModel) {
        return data.bin?.name || '';
      },
    },
    {
      title: t('common.image') || 'Image',
      field: 'image',
      sortable: true,
      render(data: OrderProductModel) {
        if (!data.image) {
          return null;
        }
        return (
          <img className="w-24" src={data.image} alt="" />
        );
      },
    },
    { title: t('common.components') || 'Components', field: 'name', sortable: true },
    { title: t('common.repairNumber') || 'Repair number', field: 'id', sortable: true },
    {
      title: t('common.status') || 'Status',
      field: 'status',
      sortable: true,
      render(data: OrderProductModel) {
        return getRepairStatusText(REPAIR_STATUS.READY);
      },
    },
    {
      title: `${t('common.price')} / ${t('common.unit').toLowerCase()}`,
      field: 'price',
      align: 'right',
      sortable: true,
      render(data: OrderProductModel) {
        return (
          <div>
            {formatCurrency(data.price)} <span className="text-blue-400">€</span>
          </div>
        );
      },
    },
    {
      title: t('common.workingTime') || 'Working time',
      field: 'workingTime',
      align: 'right',
      sortable: true,
      render(data: OrderProductModel) {
        return (
          <div>{data.count} <span className="text-blue-400">min.</span></div>
        );
      }
    },
  ];

  const renderTableFooter = (data: OrderProductModel[]) => {
    const subTotal = data.reduce((sum, item) => sum + item.price * item.count, 0);
    const taxPercent = 21;
    const tax = subTotal * taxPercent / 100;
    const total = subTotal + tax;
    const totalTime = data.reduce((sum, item) => sum + item.count, 0);

    return (
      <>
        <div className="tf col-span-4" />
        <div className="tf font-semibold pt-5">{t('common.subTotal')}:</div>
        <div className="tf justify-end pt-5">
          {formatCurrency(subTotal)} <span className="text-blue-400 ml-1">€</span>
        </div>
        <div className="tf" />

        <div className="tf col-span-4 !border-0" />
        <div className="tf font-semibold !border-0 pt-2">{t('common.tax')} ({taxPercent}%):</div>
        <div className="tf justify-end !border-0 pt-2">
          {formatCurrency(tax)} <span className="text-blue-400 ml-1">€</span>
        </div>
        <div className="tf !border-0" />

        <div className="tf col-span-4 !border-0" />
        <div className="tf font-semibold !border-0 pt-2">{t('common.total')}:</div>
        <div className="tf justify-end !border-0 pt-2">
          <span className="font-semibold">{formatCurrency(total)}</span>
          <span className="text-blue-400 ml-1">€</span>
        </div>
        <div className="tf justify-end !border-0 pt-2">
          <span className="font-semibold">{totalTime}</span>
          <span className="text-blue-400 ml-1">min.</span>
        </div>
      </>
    );
  };

  const onFilterChange = (field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <DataTable
      className={className}
      columns={tableColumns}
      data={repair.products || []}
      footer={renderTableFooter}
      cellClass="items-center !px-2 !py-3"
      headerCellClass="!px-2 !py-3"
      serverSide={false}
      sortModel={filter.sort}
      onSort={(model) => onFilterChange('sort', model)}
    />
  );
};
export default RepairDetailTable;
