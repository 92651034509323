import React, {FC, useCallback, useEffect, useState} from "react";
import * as Yup from "yup";
import {debounce} from "lodash";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {CountryCode} from "libphonenumber-js/types";
import {CheckBox, CountryInput, CustomDrawer, PhoneInput, TextInput} from "@/components";
import {CUSTOMER_TYPE} from "@/resources/enums";
import {CustomerModel} from "@/resources/models";
import {AuthService, CustomerService, ToastService} from "@/services";
import {parseAddress} from "@/utils/helpers";

export interface IEditCustomerPanelProps {
  opened: boolean;
  customer: CustomerModel;
  onClose(result?: CustomerModel): void;
}

export interface IFormData {
  customerNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  vatNumber?: string;
  companyName?: string;
  country: CountryCode;
  city: string;
  street: string;
  houseNumber: string;
  postcode: string;
  vip: boolean;
}

const EditCustomerPanel: FC<IEditCustomerPanelProps> = ({
  opened,
  customer,
  onClose,
}) => {
  const { t } = useTranslation();
  const [vatNumberError, setVatNumberError] = useState<string>();

  const isCompanyUser = customer.type === CUSTOMER_TYPE.COMPANY;

  const validationError = {
    required: t('common.validation.required'),
    invalidEmail: t('common.validation.invalidEmail'),
    invalidPhone: t('common.validation.invalidPhone'),
  };

  const formSchema = Yup.object().shape({
    customerNumber: Yup.string().required(validationError.required),
    firstName: Yup.string().required(validationError.required),
    lastName: Yup.string().required(validationError.required),
    email: Yup.string().email(validationError.invalidEmail)
      .typeError(validationError.invalidEmail)
      .required(validationError.required),
    phone: Yup.string().matches(/^\+\d{6,16}$/, validationError.invalidPhone)
      .typeError(validationError.invalidPhone)
      .required(validationError.required),
    ...(isCompanyUser && ({
      vatNumber: Yup.string().required(validationError.required),
      companyName: Yup.string().required(validationError.required),
    })),
    country: Yup.string().required(validationError.required),
    city: Yup.string().required(validationError.required),
    street: Yup.string().required(validationError.required),
    houseNumber: Yup.string().required(validationError.required),
    postcode: Yup.string().required(validationError.required),
  });

  const formik = useFormik<IFormData>({
    initialValues: {
      customerNumber: customer.customerNumber || '',
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
      email: customer.email || '',
      phone: customer.phone || '',
      vatNumber: customer.vatNumber || '',
      companyName: customer.companyName || '',
      country: customer.country || 'US',
      city: customer.city || '',
      street: customer.street || '',
      houseNumber: customer.houseNumber || '',
      postcode: customer.postcode || '',
      vip: customer.vip || false,
    },
    validationSchema: formSchema,
    onSubmit(values) {
      if (vatNumberError) {
        return;
      }
      CustomerService.patch(customer.id, {
        ...values,
        phone: values.phone.replace(/[^\d+]/g, ''),
      }).then((res) => {
        onClose(res);
        ToastService.success(t('common.changesSavedMessage'));
      }).catch((err) => {
        ToastService.showHttpError(err, t('common.changesSaveFailedMessage'));
      });
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        customerNumber: customer.customerNumber || '',
        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        email: customer.email || '',
        phone: customer.phone || '',
        vatNumber: customer.vatNumber || '',
        companyName: customer.companyName || '',
        country: customer.country || 'US',
        city: customer.city || '',
        street: customer.street || '',
        houseNumber: customer.houseNumber || '',
        postcode: customer.postcode || '',
        vip: customer.vip || false,
      },
    });
  }, [customer]);

  const validateVatNumber = useCallback(async (field: string, vatNumber: string) => {
    if (vatNumber.length < 10) {
      return;
    }

    try {
      const result = await AuthService.validateVatNumber(vatNumber, false);
      if (result.valid === 'true') {
        setVatNumberError(undefined);
        const address = parseAddress(result.traderAddress);
        formik.setFieldValue('companyName', result.traderName);
        formik.setFieldValue('street', address.street);
        formik.setFieldValue('houseNumber', address.houseNumber);
        formik.setFieldValue('postcode', address.postcode);
        formik.setFieldValue('city', address.city);
        formik.setFieldValue('country', result.countryCode);
      } else {
        throw new Error();
      }
    } catch {
      setVatNumberError(t('common.validation.invalidVatNumber') || 'Invalid VAT number');
    }
  }, [t, formik]);

  const debouncedValidateVatNumber = debounce(validateVatNumber, 500);

  return (
    <form onSubmit={formik.handleSubmit}>
      <CustomDrawer
        isOpen={opened}
        title={t('customers.updateCustomer') || ''}
        headerActions={(
          <button
            type="submit"
            className="btn btn-blue"
          >
            {t('common.save')}
          </button>
        )}
        position="end"
        contentClass="p-8"
        onClose={onClose}
      >
        <div className="flex flex-col items-start gap-6">
          <TextInput
            formik={formik}
            name="customerNumber"
            fullWidth
            label={t('common.customerNumber')}
            size="small"
          />

          <TextInput
            formik={formik}
            name="firstName"
            fullWidth
            label={t('common.firstName')}
            size="small"
          />

          <TextInput
            formik={formik}
            name="lastName"
            fullWidth
            label={t('common.lastName')}
            size="small"
          />

          <TextInput
            formik={formik}
            name="email"
            fullWidth
            label={t('common.email')}
            size="small"
          />

          <PhoneInput
            formik={formik}
            name="phone"
            fullWidth
            label={t('common.telephone')}
            size="small"
          />

          {isCompanyUser && (
            <>
              <TextInput
                formik={formik}
                name="vatNumber"
                fullWidth
                label={t('common.vatNumber')}
                size="small"
                {...(vatNumberError && ({
                  error: true,
                  helperText: vatNumberError,
                }))}
                onChange={debouncedValidateVatNumber}
              />

              <TextInput
                formik={formik}
                name="companyName"
                fullWidth
                label={t('common.company')}
                size="small"
                disabled
              />
            </>
          )}

          <TextInput
            formik={formik}
            name="street"
            fullWidth
            label={t('common.street')}
            size="small"
            disabled={isCompanyUser}
          />

          <TextInput
            formik={formik}
            name="houseNumber"
            fullWidth
            label={t('common.houseNumber')}
            size="small"
            disabled={isCompanyUser}
          />

          <TextInput
            formik={formik}
            name="postcode"
            fullWidth
            label={t('common.postcode')}
            size="small"
            disabled={isCompanyUser}
          />

          <TextInput
            formik={formik}
            name="city"
            fullWidth
            label={t('common.city')}
            size="small"
            disabled={isCompanyUser}
          />

          <CountryInput
            formik={formik}
            name="country"
            fullWidth
            label={t('common.country')}
            size="small"
            disabled={isCompanyUser}
          />

          <CheckBox
            formik={formik}
            name="vip"
            label={t('common.vip')}
          />
        </div>
      </CustomDrawer>
    </form>
  );
}

export default EditCustomerPanel;
