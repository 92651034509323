import React, {FC, useEffect, useState} from "react";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {Chip, CustomDrawer, TextInput} from "@/components";
import {LinkedCameraIcon, MinusIcon, PlusIcon} from "@/icons";
import {BIN_CONTENTS} from "@/constants";
import {DELIVERY_METHOD} from "@/resources/enums";
import {BinModel, ProductFormData, RepairModel} from "@/resources/models";
import {BinService, PrismicService, ToastService} from "@/services";

export interface AddProductsPanelProps {
  opened: boolean;
  order: RepairModel;
  onSubmit(binId: number, products: ProductFormData[]): void;
  onClose(): void;
}

export interface BinInfo {
  content: number;
  image: string;
  bin?: BinModel;
}

const AddProductsPanel: FC<AddProductsPanelProps> = ({
  opened,
  order,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  const [products, setProducts] = useState<ProductFormData[]>([]);
  const [bin, setBin] = useState<BinModel>();
  const [bins, setBins] = useState<BinInfo[]>([]);
  const [showBinsSelect, setShowBinsSelect] = useState(false);

  useEffect(() => {
    PrismicService.searchProducts(false).then((data) => {
      const products = data.map((item) => ({
        name: item.name,
        price: item.price,
        image: item.image?.url,
        count: 0,
      }));
      setProducts(products);
    }).catch((err) => {
      ToastService.showHttpError(err, t('repairs.loadingProductsFailed'));
    });
  }, [t]);

  useEffect(() => {
    if (!opened) {
      setBins([]);
      return;
    }
    BinService.getAvailableBinsByContent(order.deliveryMethod as DELIVERY_METHOD).then((data) => {
      const bins = BIN_CONTENTS
        .filter((item) => item.deliveryMethods.includes(order.deliveryMethod as DELIVERY_METHOD))
        .map((item) => {
          const bin = data.find((datum) => datum.content === item.content);
          return {
            content: item.content,
            image: item.image,
            bin,
          }
        });
      setBins(bins);
    }).catch((err) => {
      ToastService.showHttpError(err, t('bins.loadingFailedMessage'));
    });
  }, [t, order.deliveryMethod, opened]);

  useEffect(() => {
    setBin(undefined);
    setProducts((prev) => prev.map((item) => ({
      ...item,
      count: 0,
    })));
  }, [opened]);

  const onSelectBin = (bin?: BinModel) => {
    if (bin) {
      setBin(bin);
      setShowBinsSelect(false);
    }
  };

  const onChangeCount = (product: ProductFormData, count: number) => {
    setProducts(
      products.map((item) => (
        item.name === product.name ? { ...item, count } : item
      ))
    );
  };

  const selectedProducts = products.filter((item) => item.count > 0);
  const isValid = Boolean(bin && selectedProducts.length);

  const onSave = () => {
    if (!isValid) {
      return;
    }
    onSubmit(bin?.id as number,  selectedProducts);
  };

  return (
    <CustomDrawer
      isOpen={opened}
      title="Onderdeel inboeken"
      size="xl"
      position="end"
      contentClass="p-8"
      headerActions={(
        <button className="btn btn-blue" disabled={!isValid} onClick={onSave}>
          {t('repairs.bookInBin')}
        </button>
      )}
      onClose={onClose}
    >
      {(!bin || showBinsSelect) ? (
        <>
          <h6 className="font-bold">{t('repairs.selectBinFirst')}</h6>
          <div className="flex flex-wrap items-stretch gap-6 mt-4">
            {bins.map((item) => (
              <div
                key={item.content}
                className={classnames(
                  'w-65 h-full flex flex-col border rounded text-center p-4 transition-all',
                  bin?.id === item.bin?.id ? 'border-2 border-blue' : 'border-secondary hover:border-blue',
                  item.bin ? 'cursor-pointer' : 'opacity-60 cursor-default'
                )}
                onClick={() => onSelectBin(item.bin)}
              >
                <div className="text-lg font-bold uppercase">{item.content} Liters</div>
                <div className="font-bold mb-1">{item.bin?.name}</div>
                <img className="w-40 m-auto" src={item?.image} alt="" />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <h6 className="font-bold mb-2">{t('common.binNo')}</h6>
          <div className="form-field">
            <TextInput
              name="bin"
              className="max-w-130"
              value={bin.name}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <LinkedCameraIcon
                    className="text-blue cursor-pointer"
                    onClick={() => setShowBinsSelect(true)}
                  />
                )
              }}
            />
          </div>

          <hr className="bg-blue-200 my-8" />

          <h6 className="font-bold">{t('common.choosePart')}</h6>
          <div className="mt-6">
            {products.map((product) => (
              <div key={product.name} className="h-22 flex items-center border border-secondary rounded pr-8 mt-2">
                <div
                  className="w-29 h-full bg-blue-100 bg-center bg-contain bg-no-repeat rounded"
                  style={{ backgroundImage: `url(${product.image})` }}
                />
                <div className="flex-1 text-max-lines-3 break-words mx-8">{product.name}</div>
                {product.count > 0 && (
                  <Chip theme="success" size="sm" className="mr-8">{`${product.count}x`}</Chip>
                )}
                {product.count > 0 && (
                  <button className="btn icon-btn mr-2" onClick={() => onChangeCount(product, product.count - 1)}>
                    <MinusIcon />
                  </button>
                )}
                <button className="btn icon-btn" onClick={() => onChangeCount(product, product.count + 1)}>
                  <PlusIcon />
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </CustomDrawer>
  );
}

export default AddProductsPanel;
