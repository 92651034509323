import {Route, Routes} from "react-router-dom";
import Bins from "./Home";

const BinsRouting = () => (
  <Routes>
    <Route index element={<Bins />} />
  </Routes>
);

export default BinsRouting;
