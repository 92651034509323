export const WS_PROTOCOL = "https://",
  WS_START_PORT = 41951,
  WS_END_PORT = 41953,
  WS_CHECK_TIMEOUT = 3e3,
  WS_COMMAND_TIMEOUT = 1e4,
  WS_SVC_HOST = "127.0.0.1",
  WS_SVC_HOST_LEGACY = "localhost",
  WS_SVC_PATH = "DYMO/DLS/Printing",
  WS_ACTIONS = {
    status: "StatusConnected",
    getPrinters: "GetPrinters",
    openLabel: "OpenLabelFile",
    printLabel: "PrintLabel",
    printLabel2: "PrintLabel2",
    renderLabel: "RenderLabel",
    loadImage: "LoadImageAsPngBase64",
    getJobStatus: "GetJobStatus",
  };
