export enum ORDER_LOG_ACTION {
  CUSTOMER_REGISTRATION = 'CUSTOMER_REGISTRATION',
  REGISTRATION_START = 'REGISTRATION_START',
  REGISTRATION_END = 'REGISTRATION_END',
  UNPACKING_START = 'UNPACKING_START',
  UNPACKING_END = 'UNPACKING_END',
  BOOKING_START = 'BOOKING_START',
  BOOKING_END = 'BOOKING_END',
  WORKTABLE_START = 'WORKTABLE_START',
  WORKTABLE_END = 'WORKTABLE_END',
  INSPECTION_START = 'INSPECTION_START',
  INSPECTION_END = 'INSPECTION_END',
  PICKUP_DELIVERY = 'PICKUP_DELIVERY',
  PICKUP_URGENTLY = 'PICKUP_URGENTLY',
  PAYMENT_UNCOMPLETED = 'PAYMENT_UNCOMPLETED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  UPDATED = 'UPDATED',
  UPDATE_PRODUCTS = 'UPDATE_PRODUCTS',
}

export const ORDER_LOG_ACTION_VALUES = Object.values(ORDER_LOG_ACTION);
