import {HttpService} from "./http.service";
import {SearchOrderLogsRequestDto} from "@/resources/dtos";
import {OrderLogModel} from "@/resources/models";
import {formatPaginatedResult, trimObject} from "@/utils/helpers";

export class OrderLogService {

  static search(data: SearchOrderLogsRequestDto, showSpinner = true) {
    return HttpService.get('/order-logs', trimObject(data), showSpinner)
      .then((res) => formatPaginatedResult<OrderLogModel>(OrderLogModel, res));
  }

  static find(id: string, showSpinner = true) {
    return HttpService.get(`/order-logs/${id}`, {}, showSpinner)
      .then((res) => new OrderLogModel(res));
  }
}
