export const compareValues = (a: any, b: any) => {
  const emptyValues = [null, undefined];
  if (emptyValues.includes(a) && emptyValues.includes(b)) {
    return 0;
  } else if (emptyValues.includes(b)) {
    return -1;
  } else if (emptyValues.includes(a)) {
    return 1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  return a.toString().localeCompare(b.toString());
};
