import React, {FC} from "react";
import classnames from "classnames";
import {FormikProps} from "formik";
import ReactPhoneInput from "react-phone-number-input";
import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import "./style.scss";

const PhoneInputComponent = (props: any) => (
  <ReactPhoneInput
    {...props}
    withCountryCallingCode={true}
  />
);

export type PhoneInputProps = {
  className?: string;
  name: string;
  formik?: FormikProps<any>;
  onChange?: (field: string, value: string) => void;
} & Omit<TextFieldProps, 'onChange'>;

export const PhoneInput: FC<PhoneInputProps> = ({
  className,
  name,
  formik,
  onChange,
  ...props
}) => {
  const onInputChange = (value: string) => {
    if (formik) {
      formik.setFieldValue(name, value);
      formik.setFieldTouched(name);
    }
    if (onChange) {
      onChange(name, value);
    }
  };

  return (
    <TextField
      className={classnames('phone-input', className)}
      name={name}
      {...formik && (
        {
          value: formik.values[name] || '',
          error: formik.touched[name] && Boolean(formik.errors[name]),
          helperText: formik.touched[name] ? (formik.errors[name] as string) : '',
          onBlur: formik.handleBlur,
        }
      )}
      InputProps={{
        inputComponent: PhoneInputComponent
      }}
      InputLabelProps={{
        shrink: true
      }}
      {...props}
      onChange={(e: any) => onInputChange(e)}
    />
  );
};
