import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Repairs from "./Home";
import RepairDetail from "@/pages/Repairs/Detail";
import { useAppDispatch } from "@/redux/hooks";
import { setRepairStatusFilter } from "@/redux/reducers/repair.reducer";
import { REPAIR_STATUS } from "@/resources/enums";

const RepairsRouting = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setLoading(true);
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', window.location.href, true);
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        const headers = xhr.getAllResponseHeaders();
        const match = /x-status-filter-repairs: (\w+)/ig.exec(headers);
        if (match) {
          dispatch(setRepairStatusFilter(match[1] as REPAIR_STATUS));
        }
        setLoading(false);
      }
    };
    xhr.send(null);
  }, [dispatch]);

  if (loading) {
    return null;
  }

  return (
    <Routes>
      <Route index element={<Repairs />} />
      <Route path=":id" element={<RepairDetail />} />
    </Routes>
  );
}

export default RepairsRouting;
