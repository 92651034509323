import React, {FC, useMemo, useState} from "react";
import classnames from "classnames";
import PhoneInput from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import de from "react-phone-number-input/locale/de.json";
import fr from "react-phone-number-input/locale/fr.json";
import it from "react-phone-number-input/locale/it.json";
import nl from "react-phone-number-input/locale/nl.json";
import {CountryCode} from "libphonenumber-js/types";
import {getCurrentLanguage} from "@/utils/helpers";
import "./style.scss";

const localeLabels: { [lang: string]: { [key: string]: string } } = {
  en, de, fr, it, nl
};

interface CountrySelectProps {
  className?: string;
  value?: CountryCode;
  disabled?: boolean;
  onChange(value: CountryCode): void;
}

export const CountrySelect: FC<CountrySelectProps> = ({
  className,
  value = 'US',
  onChange,
  ...props
}) => {
  const [countryCode, setCountryCode] = useState<CountryCode>(value);
  const locale = getCurrentLanguage() || 'en';

  const labels = useMemo(() => (
    localeLabels[locale] || en
  ), [locale]);

  const countryName = labels[countryCode];

  const onSelectCountry = (countryCode: CountryCode) => {
    onChange(countryCode);
    setCountryCode(countryCode);
  };

  return (
    <div className={classnames('country-select', className)}>
      <PhoneInput
        {...props}
        placeholder=""
        value=""
        onChange={() => undefined}
        defaultCountry={countryCode}
        withCountryCallingCode={false}
        addInternationalOption={false}
        onCountryChange={onSelectCountry}
      />

      <p className="country-name">
        {countryName}
      </p>
      <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2">
        <svg
          className="fill-current h-5 w-5 text-green-600"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_536_179)">
            <path d="M7 10L12 15L17 10L7 10Z" fill="#2165B7" />
          </g>
          <defs>
            <clipPath id="clip0_536_179">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};
