import { FC, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider, Menu, MenuItem } from "@mui/material";
import { SearchBox } from "@/components";
import { AccountCircleIcon, MenuIcon, MiniLogoIcon } from "@/icons";
import { useAppSelector } from "@/redux/hooks";
import { AuthService } from "@/services";

export interface ILayoutHeaderProps {
  onToggleSidebar(): void;
  onSearchChange?: (field: 'search', value: string) => void;
  children?: ReactNode;
}

const LayoutHeader: FC<ILayoutHeaderProps> = ({
  onToggleSidebar,
  onSearchChange,
  children,
}) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState<HTMLElement>();

  const onCloseProfileMenu = () => {
    setProfileMenuAnchor(undefined);
  };

  const onLogout = () => {
    AuthService.logout();
    onCloseProfileMenu();
  };

  return (
    <div className="w-full h-20 flex items-center justify-between bg-white px-4 sm:px-10 py-4 shadow-sm">
      {children || (
        <>
          <button className="icon-btn" onClick={onToggleSidebar}>
            <MenuIcon />
          </button>

          <Link className="mx-4" to="/">
            <MiniLogoIcon />
          </Link>

          <div className="w-158 shrink mx-auto">
            <SearchBox
              placeholder={t('layout.header.searchBox.placeholder') || ''}
              onChange={onSearchChange}
            />
          </div>

          <div
            className="max-sm:hidden flex-center text-blue gap-2.5 whitespace-nowrap ml-4 cursor-pointer"
            onClick={(e) => setProfileMenuAnchor(e.currentTarget)}
          >
            <AccountCircleIcon />
            <span className="font-semibold">{user!.fullName}</span>
          </div>
          <button
            className="sm:hidden icon-btn ml-4"
            onClick={(e) => setProfileMenuAnchor(e.currentTarget)}
          >
            <AccountCircleIcon size={32} />
          </button>
          <Menu
            classes={{ paper: '!w-32 mt-2' }}
            anchorEl={profileMenuAnchor}
            open={Boolean(profileMenuAnchor)}
            onClose={onCloseProfileMenu}
          >
            <MenuItem onClick={onCloseProfileMenu}>Profile</MenuItem>
            <Divider />
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
}

export default LayoutHeader;
