import React, {FC, ReactNode, useEffect, useState} from "react";
import {CONFIG} from "@/constants";
import {useAppDispatch, useAppSelector} from "@/redux/hooks";
import {setToken, setUser} from "@/redux/reducers/auth.reducer";
import {AuthService} from "@/services";

export interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<IAuthProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(setToken(localStorage.getItem(CONFIG.TOKEN_KEY)) || null);
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    if (token === undefined) {
      setLoading(false);
      return;
    }
    if (!token) {
      dispatch(setUser(undefined));
      setLoading(false);
      return;
    }
    AuthService.me().then((employer) => {
      dispatch(setUser(employer));
    }).catch(() => {
      dispatch(setToken(null));
      dispatch(setUser(undefined));
    }).finally(() => {
      setLoading(false);
    });
  }, [token, dispatch]);

  if (loading) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};
