export const trimObject = (object: any, removals = [undefined, null, '']) => {
  if (typeof object !== 'object' || Array.isArray(object)) {
    return object;
  }

  const result: any = {};
  Object.entries(object).forEach(([key, value]: [string, any]) => {
    if (!removals.includes(value)) {
      result[key] = value;
    }
  });
  return result;
};
