export const ROUTES = {
  AUTH: {
    PREFIX: '/',
    LOGIN: '/login',
    RESET_PASSWORD: '/reset-password',
  },
  REPAIRS: {
    PREFIX: '/repairs',
    INDEX: '/repairs',
  },
  CUSTOMERS: {
    PREFIX: '/customers',
    INDEX: '/customers',
  },
  BINS: {
    PREFIX: '/bins',
    INDEX: '/bins',
  },
  COMPONENTS: {
    PREFIX: '/components',
    INDEX: '/components',
  },
  WORKTABLES: {
    PREFIX: '/worktables',
    INDEX: '/worktables',
  },
  EMPLOYEES: {
    PREFIX: '/employees',
    INDEX: '/employees',
  },
  CONFIGURATION: {
    PREFIX: '/configuration',
    INDEX: '/configuration',
  },
}
