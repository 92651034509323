import {Navigate, Route, Routes} from "react-router-dom";
import Login from "@/pages/Auth/Login";

const AuthRouting = () => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

export default AuthRouting;
