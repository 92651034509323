import React, {FC, ReactElement, ReactNode, useMemo, useState} from "react";
import {get} from "lodash";
import classnames from "classnames";
import {Paginator} from "@/components";
import {AngleDownIcon, ArrowUpIcon} from "@/icons";
import {compareValues} from "@/utils/helpers";
import "./style.scss";

export interface IDataTableColumn {
  title?: string;
  field: string;
  minWidth?: string;
  maxWidth?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  headerClass?: string;
  cellClass?: string;
  sortable?: boolean;
  getText?: (row: any, index: number, data: any[]) => string;
  render?: (row: any, index: number, data: any[]) => string | ReactElement | null;
}

export type TableTheme = 'default' | 'card-row';
export type TableSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type ClassFn = (row: any, id: number) => string;

export interface ISortModel {
  field: string;
  dir: 'ASC' | 'DESC';
}

export interface IGroupModel {
  field: string;
  dir: 'ASC' | 'DESC';
}

export interface IPaginationModel {
  page: number;
  perPage: number;
}

export interface IRowGroupData {
  value: any;
  children: any[];
}

export interface IDataTableProps {
  className?: string;
  theme?: TableTheme;
  size?: TableSize;
  columns: IDataTableColumn[];
  data: any[];
  footer?: (data: any[]) => ReactNode;
  headerCellClass?: string;
  cellClass?: string | ClassFn;
  paginationClass?: string;
  stripped?: boolean;
  serverSide?: boolean;
  search?: string;
  multiSort?: boolean;
  sortModel?: ISortModel[];
  groupModel?: IGroupModel;
  pagination?: boolean | 'auto';
  paginationModel?: IPaginationModel;
  totalCount?: number;
  onSort?: (model: ISortModel[]) => void;
  onPaginate?: (model: IPaginationModel) => void;
}

export const DataTable: FC<IDataTableProps> = ({
  className = '',
  theme = 'default',
  size = 'md',
  columns,
  data,
  footer,
  headerCellClass = '',
  cellClass = '',
  paginationClass = '',
  stripped = false,
  serverSide = false,
  search,
  multiSort,
  sortModel,
  groupModel,
  pagination = false,
  paginationModel,
  totalCount = 0,
  onSort,
  onPaginate,
}) => {
  const [closedGroups, setClosedGroups] = useState<IRowGroupData[]>([]);

  const tableData = useMemo(() => {
    if (serverSide) {
      return data;
    }

    let filteredData = data;
    if (search) {
      const keywords = search.toLowerCase();
      filteredData = filteredData.filter((item) => (
        columns.some((column) => {
          const value = (get(item, column.field) || '').toString().toLowerCase();
          return value.includes(keywords);
        })
      ));
    }

    if (sortModel?.length) {
      filteredData = [...filteredData].sort((a, b) => {
        for (const model of sortModel) {
          const val1: any = get(a, model.field);
          const val2: any = get(b, model.field);
          const dir = model.dir === 'ASC' ? 1 : -1;
          const result = compareValues(val1, val2);
          if (result) {
            return result * dir;
          }
        }
        return 0;
      });
    }

    if (groupModel) {
      const groups: any = {};
      filteredData.forEach((item) => {
        const value = (get(item, groupModel.field) || '').toString();
        if (groups[value]) {
          groups[value].push(item);
        } else {
          groups[value] = [item];
        }
      });

      filteredData = Object.entries(groups)
        .map(([key, value]) => ({
          value: key,
          children: value,
        }))
        .sort((a, b) => (
          compareValues(a.value, b.value) * (groupModel.dir === 'ASC' ? 1 : -1)
        ));
    }

    return filteredData;
  }, [data, serverSide, columns, search, sortModel, groupModel]);

  const columnTemplate = useMemo(() => {
    return columns.map((column) => {
      if (column.minWidth && column.maxWidth) {
        return `minmax(${column.minWidth}, ${column.maxWidth})`;
      }
      if (column.minWidth) {
        return `minmax(${column.minWidth}, max-content)`;
      }
      if (column.maxWidth) {
        return `minmax(auto, ${column.maxWidth})`;
      }
      return 'auto';
    }).join(' ');
  }, [columns]);

  const showPagination = useMemo(() => (
    paginationModel && (pagination === true || (pagination === 'auto' && totalCount > paginationModel.perPage))
  ), [pagination, paginationModel, totalCount]);

  const totalPageCount = useMemo(() => {
    if (!paginationModel) {
      return 0;
    }
    return Math.ceil(totalCount / paginationModel.perPage);
  }, [paginationModel, totalCount]);

  const onPageChange = (page: number) => {
    if (paginationModel && onPaginate) {
      onPaginate({
        ...paginationModel,
        page,
      });
    }
  };

  const onSortColumn = (column: IDataTableColumn) => {
    if (!column.sortable || !onSort) {
      return;
    }

    const model = sortModel?.find((item) => item.field === column.field);
    if (multiSort) {
      if (!model) {
        onSort([...(sortModel || []), { field: column.field, dir: 'ASC' }]);
      } else if (model.dir === 'DESC') {
        onSort((sortModel || []).filter((item) => item.field !== column.field));
      } else {
        onSort((sortModel || []).map((item) => (
          item.field === column.field ? { ...item, dir: model?.dir ? 'DESC' : 'ASC' } : item
        )));
      }
    } else {
      if (model?.dir === 'DESC') {
        onSort([]);
      } else {
        onSort([{ field: column.field, dir: model?.dir ? 'DESC' : 'ASC' }]);
      }
    }
  };

  const onToggleOpenGroup = (group: IRowGroupData) => {
    setClosedGroups((prev) => {
      if (prev.includes(group)) {
        return prev.filter((item) => item !== group);
      }
      return [...prev, group];
    });
  };

  const getColumnAlignClass = (column: IDataTableColumn) => {
    return classnames(
      column.align === 'left' && 'justify-start',
      column.align === 'center' && 'justify-center',
      column.align === 'right' && 'justify-end',
      column.align === 'justify' && 'justify-between',
    );
  };

  const renderHeaderRow = (column: IDataTableColumn, colId: number) => {
    const model = sortModel?.find((item) => item.field === column.field);
    return (
      <div
        key={colId}
        className={classnames(
          'thead th',
          column.sortable && 'cursor-pointer',
          headerCellClass,
          column.headerClass,
          getColumnAlignClass(column),
        )}
        onClick={() => onSortColumn(column)}
      >
        {column.title || ''}
        {column.sortable && (
          <ArrowUpIcon
            className={classnames(
              'text-blue transition-all',
              column.align === 'right' ? '-order-1 mr-2' : 'ml-2',
              !model?.dir && 'opacity-0',
              model?.dir === 'ASC' && 'rotate-180',
            )}
            size={20}
          />
        )}
      </div>
    );
  };

  const renderBodyRow = (row: any, rowId: number) => {
    const trClass = classnames(
      `tr-${rowId}`,
      rowId % 2 ? 'row-even' : 'row-odd',
    );

    return (
      <React.Fragment key={rowId}>
        {columns.map((column, colId) => {
          const tdClass = classnames(
            'td',
            `td-${colId}`,
            colId === 0 && 'td-first',
            colId === columns.length - 1 && 'td-last',
            typeof cellClass === 'function' ? cellClass(row, rowId) : cellClass,
          );

          let text: ReactNode;
          if (column.render) {
            text = column.render(row, rowId, data);
          } else if (column.getText) {
            text = column.getText(row, rowId, data);
          } else if (column.field) {
            text = get(row, column.field) || '';
          }

          return (
            <div
              key={colId}
              className={classnames(trClass, tdClass, column.cellClass, getColumnAlignClass(column))}
            >
              {text}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  const renderRowGroups = (group: IRowGroupData, groupId: number) => {
    if (!Array.isArray(group.children)) {
      return null;
    }

    const opened = !closedGroups.includes(group);

    const groupRowClass = classnames(
      'group-row cursor-pointer',
       opened && 'opened',
    );

    return (
      <React.Fragment key={groupId}>
        <div
          className={groupRowClass}
          style={{ gridColumn: `1 / ${columns.length + 1}` }}
          onClick={() => onToggleOpenGroup(group)}
        >
          <AngleDownIcon className={classnames('transition-all mr-2', !opened && 'rotate-180')} />
          <span className="title">{group.value || '[NO VALUE]'}</span>
          <span className="badge">{group.children.length}</span>
        </div>

        {opened && group.children.map(renderBodyRow)}
      </React.Fragment>
    );
  };

  return (
    <div className={classnames(
      `datatable-wrapper w-full relative table-${size} theme-${theme} overflow-auto`,
      className,
    )}>
      <div
        className={classnames('datatable', stripped && 'table-stripped')}
        style={{ gridTemplateColumns: columnTemplate }}
      >
        {columns.map(renderHeaderRow)}

        {groupModel ? (
          tableData.map(renderRowGroups)
        ) : (
          tableData.map(renderBodyRow)
        )}

        {!tableData.length && (
          <div
            className="td td-first td-last justify-center text-blue-400 no-records"
            style={{ gridColumn: `1 / ${columns.length + 1}` }}
          >
            No records
          </div>
        )}

        {footer && footer(tableData)}
      </div>

      {showPagination && paginationModel && (
        <Paginator
          className={classnames('mx-auto mt-4', paginationClass)}
          page={paginationModel.page}
          pageCount={totalPageCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};
