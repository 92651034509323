import React, {FC, ReactNode} from "react";
import classnames from "classnames";
import {CloseIcon} from "@/icons";

export type ChipSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ChipTheme = 'primary' | 'success' | 'warning' | 'danger' | 'secondary' | 'blue-800';
export type ChipVariant = 'fill' | 'outline';

export interface IChipProps {
  className?: string;
  size?: ChipSize;
  theme?: ChipTheme;
  variant?: ChipVariant;
  active?: boolean;
  clickable?: boolean;
  disabled?: boolean;
  onRemove?: () => void;
  onClick?: () => void;
  children?: ReactNode;
}

export const Chip: FC<IChipProps> = ({
  className = '',
  size = 'md',
  theme = 'secondary',
  variant = 'fill',
  active,
  clickable,
  disabled,
  onRemove,
  onClick,
  children,
}) => {
  return (
    <button
      type="button"
      className={classnames(
        'flex-center shrink-0 whitespace-nowrap font-semibold rounded-full outline-none transition-all',
        (!clickable || disabled) && 'cursor-default',
        { 'h-9 text-sm px-4 py-2': size === 'sm' },
        { 'text-md px-5 py-3': size === 'md' },
        { 'bg-primary-light text-primary-dark': theme === 'primary' && variant === 'fill' },
        { 'bg-success-light text-success-dark': theme === 'success' && variant === 'fill' },
        { 'bg-warning-light text-warning-dark': theme === 'warning' && variant === 'fill' },
        { 'bg-danger-light text-danger-dark': theme === 'danger' && variant === 'fill' },
        { 'bg-[#EFEFEF] text-gray': theme === 'secondary' && variant === 'fill' },
        { 'bg-blue-800 text-white': theme === 'blue-800' && variant === 'fill' },
        { 'border border-secondary text-blue': theme === 'blue-800' && variant === 'outline' },
        { '!bg-blue-800 !text-white': theme === 'blue-800' && variant === 'outline' && active },
        { 'hover:bg-blue-800 hover:text-white': theme === 'blue-800' && variant === 'outline' && clickable },
        disabled && 'opacity-60',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      { children }
      {onRemove && (
        <CloseIcon className="cursor-pointer ml-2" size={20} onClick={onRemove} />
      )}
    </button>
  );
};
