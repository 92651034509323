import React, {ChangeEventHandler, FC, ReactNode} from "react";
import {FormikProps} from "formik";
import {Checkbox, CheckboxProps, FormControlLabel} from "@mui/material";

export type CheckBoxProps = {
  className?: string;
  name: string;
  label?: ReactNode;
  formik?: FormikProps<any>;
  onChange?: (field: string, value: boolean) => void;
} & Omit<CheckboxProps, 'onChange'>;

export const CheckBox: FC<CheckBoxProps> = ({
  name,
  label,
  formik,
  onChange,
  ...props
}) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (formik) {
      formik.handleChange(e);
    }
    if (onChange) {
      onChange(name, e.target.checked);
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          {...formik && (
            {
              checked: formik.values[name] || false,
              error: formik.touched[name] && Boolean(formik.errors[name]),
              helperText: formik.touched[name] ? (formik.errors[name] as string) : '',
              onBlur: formik.handleBlur,
            }
          )}
          {...props}
          onChange={onInputChange}
        />
      }
      label={label}
    />
  );
};
