import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputBase, MenuItem, Select } from "@mui/material";
import { IFilter } from "@/pages/Customers/Home";
import { CUSTOMER_TYPE, CUSTOMER_TYPES, YES_NO_OPTIONS } from "@/resources/enums";
import { CustomerService } from "@/services";

export interface IFilterProps {
  filter: IFilter;
  onChange(updates: Partial<IFilter>): void;
}

interface ITypeOption {
  text: string;
  value: CUSTOMER_TYPE | '';
  count: number;
}

interface IVipOption {
  text: string;
  value: string | '';
  count: number;
}

const Filter: FC<IFilterProps> = ({
  filter,
  onChange,
}) => {
  const { t } = useTranslation();
  const [typeOptions, setTypeOptions] = useState<ITypeOption[]>([]);
  const [vipOptions, setVipOptions] = useState<IVipOption[]>([]);

  useEffect(() => {
    CustomerService.count({ field: 'type' }).then((data) => {
      let totalCount = 0;
      const typeCount: ITypeOption[] = CUSTOMER_TYPES.map((option) => {
        const result = data.find((item: any) => item.type === option.value);
        totalCount += +(result?.count || 0);
        return {
          text: option.text,
          value: option.value,
          count: result?.count || 0,
        };
      });
      typeCount.unshift({
        text: t('common.all'),
        value: '',
        count: totalCount,
      })
      setTypeOptions(typeCount);
    });

    CustomerService.count({ field: 'vip' }).then((data) => {
      let totalCount = 0;
      const vipCount: IVipOption[] = YES_NO_OPTIONS.map((option) => {
        const result = data.find((item: any) => item.vip === option.value);
        totalCount += +(result?.count || 0);
        return {
          text: option.text,
          value: option.value.toString(),
          count: result?.count || 0,
        };
      });
      vipCount.unshift({
        text: t('common.all'),
        value: '',
        count: totalCount,
      })
      setVipOptions(vipCount);
    });
  }, [t]);

  const onFilterChange = (field: keyof IFilter, value: any) => {
    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }
    onChange({
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex items-center justify-between flex-wrap max-sm:w-full gap-2">
        <Select
          className="filter-select"
          value={filter.type || ''}
          onChange={(e) => onFilterChange('type', e.target.value)}
          input={<InputBase />}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return t('common.type');
            }
            const option = typeOptions.find((item) => item.value === selected);
            return option?.text || selected;
          }}
          MenuProps={{
            className: 'filter-select-menu',
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            transformOrigin: { horizontal: 'left', vertical: 'top' },
          }}
        >
          {typeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <div className="flex items-center justify-between grow">
                <span className="text-blue-900">{option.text}</span>
                <span className="text-blue-400 ml-4 mr-6">{option.count}</span>
              </div>
            </MenuItem>
          ))}
        </Select>

        <Select
          className="filter-select"
          value={filter.vip}
          onChange={(e) => onFilterChange('vip', e.target.value)}
          input={<InputBase />}
          displayEmpty
          renderValue={(selected) => {
            if (typeof selected !== 'boolean') {
              return t('common.vip');
            }
            const option = vipOptions.find((item) => item.value === selected.toString());
            return option?.text || selected;
          }}
          MenuProps={{
            className: 'filter-select-menu',
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            transformOrigin: { horizontal: 'left', vertical: 'top' },
          }}
        >
          {vipOptions.map((option) => (
            <MenuItem key={option.text} value={option.value}>
              <div className="flex items-center justify-between grow">
                <span className="text-blue-900">{option.text}</span>
                <span className="text-blue-400 ml-4 mr-6">{option.count}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  );
};
export default Filter;
