import {Route, Routes} from "react-router-dom";
import Configuration from "./Home";

const ConfigurationRouting = () => (
  <Routes>
    <Route index element={<Configuration />} />
  </Routes>
);

export default ConfigurationRouting;
