import i18next from "@/i18n/config";

export enum YES_NO {
  YES = 'yes',
  NO = 'no',
}

export const YES_NO_VALUES = Object.values(YES_NO);

export const YES_NO_OPTIONS = YES_NO_VALUES.map((item) => ({
  text: i18next.t(`common.${item}`),
  value: item === 'yes',
}));
