import axios from "axios";

import {
  WS_PROTOCOL,
  WS_SVC_HOST,
  WS_SVC_HOST_LEGACY,
  WS_START_PORT,
  WS_END_PORT,
  WS_SVC_PATH,
  WS_ACTIONS,
} from "./constants";
import {localRetrieve, localStore} from "./storage";

async function storeDymoRequestParams() {
  let { activeHost, activePort } = {};
  const storedParams = localRetrieve("dymo-ws-request-params");

  if (storedParams) {
    activeHost = storedParams.activeHost;
    activePort = storedParams.activePort;
    window.localStorage.removeItem("dymo-ws-request-params");
  }

  const hostList = [WS_SVC_HOST, WS_SVC_HOST_LEGACY];

  for (const currentHost of hostList) {
    for (let currentPort = WS_START_PORT; currentPort <= WS_END_PORT; currentPort++) {
      if (currentHost === activeHost && currentPort === Number.parseInt(activePort)) {
        continue;
      }
      try {
        const url = dymoUrlBuilder(WS_PROTOCOL, currentHost, currentPort, WS_SVC_PATH, "status");
        const response = await axios.get(url);
        const [successRequestHost, successRequestPort] = new URL(response.config.url).host.split(":");
        localStore("dymo-ws-request-params", { activeHost: successRequestHost, activePort: successRequestPort });
        return;
      } catch (error) {}
    }
  }
}

export async function dymoRequestBuilder({
  wsProtocol = WS_PROTOCOL,
  wsPath = WS_SVC_PATH,
  wsAction,
  method,
  cancelToken,
  axiosOtherParams = {},
}) {
  if (!localRetrieve("dymo-ws-request-params")) {
    await storeDymoRequestParams();
  }
  const {activeHost, activePort} = localRetrieve("dymo-ws-request-params");

  const dymoAxiosInstance = axios.create();
  dymoAxiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (axios.isCancel(error) || error?.response?.status === 500) {
        return Promise.reject(error);
      }
      await storeDymoRequestParams();
      if (!localRetrieve("dymo-ws-request-params")) {
        return Promise.reject(error);
      }
      try {
        const {activeHost, activePort} = localRetrieve("dymo-ws-request-params");
        const response = await axios.request({
          url: dymoUrlBuilder(wsProtocol, activeHost, activePort, wsPath, wsAction),
          method,
          cancelToken,
          ...axiosOtherParams,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  );
  const request = await dymoAxiosInstance.request({
    url: dymoUrlBuilder(wsProtocol, activeHost, activePort, wsPath, wsAction),
    method,
    cancelToken,
    ...axiosOtherParams,
  });
  return request;
}

export function dymoUrlBuilder(wsProtocol, wsHost, wsPort, wsPath, wsAction) {
  return `${wsProtocol}${wsHost}:${wsPort}/${wsPath}/${WS_ACTIONS[wsAction]}`;
}

// Converts the XML response thrown by the dymo service for GetPrinters in a object with the following structure
// Only return the printers type => "LabelWriterPrinters"
//   {
//      name: "",
//      modelName: "",
//      isLocal: "",
//      isTwinTurbo: "",
//      isConnected: "",
//   }
//
export function getDymoPrintersFromXml(xml, modelPrinter) {
  const xmlDoc = new DOMParser().parseFromString(xml, "text/xml");
  return Array.from(xmlDoc.getElementsByTagName(modelPrinter)).map(printer => {
    const printerDetails = {};
    Array.from(printer.children).forEach(item => {
      printerDetails[item.tagName.charAt(0).toLowerCase() + item.tagName.slice(1)] = item.textContent;
    });
    return printerDetails;
  });
}

/**
 * Print dymo labels
 *
 * @param {string} printerName - The Dymo Printer to print on
 * @param {string} labelXml - Label XML parsed to string
 * @param {string} labelSetXml - LabelSet to print. LabelSet is used to print multiple labels with same layout but different data, e.g. multiple addresses.
 * @returns AxiosResponse
 */
export function printLabel(printerName, labelXml, labelSetXml, printerParams ) {
  const printParamsXml = printerParams ? createLabelWriterPrintParamsXml(printerParams) : '';
  return dymoRequestBuilder({
    method: "POST",
    wsAction: "printLabel",
    axiosOtherParams: {
      data: [
        `printerName=${encodeURIComponent(printerName)}&`,
        `printParamsXml=${printParamsXml}&`,
        `labelXml=${encodeURIComponent(labelXml)}&`,
        `labelSetXml=${labelSetXml || ""}`,
      ].join('')
    },
  });
}

/** Creates an xml stream suatable to pass to printLabel() function as printParamsXml parameter
// Created printing parameters are for printing on LabelWriter printers
// Parameters:
// - params - an JavaScript object with following properties (not all properties have to be defined, if a property is not defined a default value will be used)
//      params.copies - number of copies to print
//      params.jobTitle - print job title/description
//      params.flowDirection - prints label content as left-to-right or right-to-left use FlowDirection enum to specify values
//      params.printQuality - printing quality, one of 'Text', 'BarcodeAndGraphics', or 'Auto'
//      params.twinTurboRoll - the roll to print on if the printer is TwinTurbo. One of 'Left", 'Right', or 'Auto'

    @param {LabelWriterPrintParams} params
    @return {string}
    @export
*/
export function createLabelWriterPrintParamsXml(params) {
    if (!params)
        return "";

    // Create a new XML document
    var doc = document.implementation.createDocument(null, "LabelWriterPrintParams");
    var root = doc.documentElement;

    // Helper function to create and append a new element with text content
    function appendElementWithText(parent, tagName, textContent) {
        var element = doc.createElement(tagName);
        element.textContent = textContent;
        parent.appendChild(element);
    }

    if (params.copies)
        appendElementWithText(root, "Copies", params.copies.toString());

    if (params.jobTitle)
        appendElementWithText(root, "JobTitle", params.jobTitle);

    if (params.flowDirection)
        appendElementWithText(root, "FlowDirection", params.flowDirection);

    if (params.printQuality)
        appendElementWithText(root, "PrintQuality", params.printQuality);

    if (params.twinTurboRoll)
        appendElementWithText(root, "TwinTurboRoll", params.twinTurboRoll);

    // Serialize the XML document to a string
    var serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
}
