import { CountryCode } from "libphonenumber-js/types";
import { CUSTOMER_TYPE } from "../enums";

export class CustomerModel {
  id: number;
  customerNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  type: CUSTOMER_TYPE;
  phone: string;
  vatNumber?: string;
  companyName?: string;
  country?: CountryCode;
  city: string;
  street: string;
  houseNumber: string;
  postcode: string;
  vip: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<CustomerModel>) {
    const data = {
      id: 0,
      customerNumber: '',
      email: '',
      firstName: '',
      lastName: '',
      type: CUSTOMER_TYPE.PRIVATE,
      phone: '',
      city: '',
      street: '',
      houseNumber: '',
      postcode: '',
      vip: false,
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    this.customerNumber = data.customerNumber;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.type = data.type;
    this.phone = data.phone;
    this.vatNumber = data.vatNumber;
    this.companyName = data.companyName;
    this.country = data.country;
    this.city = data.city;
    this.street = data.street;
    this.houseNumber = data.houseNumber;
    this.postcode = data.postcode;
    this.vip = data.vip;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get address() {
    return `${this.street} ${this.houseNumber}, ${this.postcode}, ${this.city}`;
  }
}
