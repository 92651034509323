import { useCallback } from "react";
import { RepairModel } from "@/resources/models";
import { printLabel } from "@/hooks/dymo";
import { ToastService } from "@/services";
import {
  IDymoPrintParams,
  IDymoPrintStatus,
  LabelSize,
  PrinterModelAndParms,
} from "./types";
import { formatDate } from "@/utils/helpers";
import { labelToPrinterMap } from "./constants";
import { dymoLargeLabel } from "./dymoLabelLarge";
import { dymoMediumLabel } from "./dymoLabelMedium";
import { dymoSmallLabel } from "./dymoLabelSmall";

function getFirstAvailablePrinterModel(
  printerModels: PrinterModelAndParms[] | undefined,
  availablePrinters: IDymoPrintStatus[]
): {
  availablePrinter: IDymoPrintStatus;
  printerModel: PrinterModelAndParms;
} | null {
  if (!printerModels) {
    return null;
  }
  for (const printerModel of printerModels) {
    const printerStatus = availablePrinters.find(
      (printer) =>
        printer.modelName === printerModel.printerModel &&
        printer.isConnected === "True"
    );
    if (printerStatus) {
      return { availablePrinter: printerStatus, printerModel: printerModel };
    }
  }
  return null;
}

export const usePrintLabelsCallback = (
  repair: RepairModel | undefined,
  printers: IDymoPrintStatus[],
  url: string,
  binId: number | undefined
) => {
  async function handlePrintSingleLabel(
    printerName: string,
    printerParams: IDymoPrintParams | null,
    labelXml: string,
    labelSize: LabelSize
  ) {
    try {
      await printLabel(printerName, labelXml, "", printerParams);
      ToastService.success(
        `Printing ${labelSize} label to ${printerName} complete`
      );
    } catch (error) {
      console.error(error);
      ToastService.error(
        `Printing ${labelSize} label to ${printerName} failed`
      );
    }
  }

  return useCallback(async () => {
    if (
      !repair ||
      !repair.products ||
      repair.products.length === 0 ||
      typeof binId === "undefined"
    ) {
      return;
    }
    const availablePrinters = printers.filter(
      (curr: any) => curr && curr.isConnected.toLowerCase() === "true"
    );
    const labelDate: string = formatDate(repair.startUnpackingAt, "label");
    const productsForBin =
      typeof binId === "number"
        ? repair.products.filter((item) => item.binId === binId)
        : repair.products;

    // Small labels
    const smallLabelPrinter = getFirstAvailablePrinterModel(
      labelToPrinterMap.get("small"),
      availablePrinters
    );
    if (smallLabelPrinter) {
      for await (const orderProductModel of productsForBin) {
        const labelXml = dymoSmallLabel(
          `${orderProductModel.bin?.name}\n${orderProductModel.id}`,
          url
        );
        // Use await because DymoPrintService crashes if to many requests are send at once.
        await handlePrintSingleLabel(
          smallLabelPrinter.availablePrinter.name,
          smallLabelPrinter.printerModel.printerParams || null,
          labelXml,
          "small"
        );
      }
    } else {
      ToastService.warning(`No printer available for small labels`);
    }
    // Medium labels
    const mediumLabelPrinter = getFirstAvailablePrinterModel(
      labelToPrinterMap.get("medium"),
      availablePrinters
    );
    if (mediumLabelPrinter) {
      for await (const orderProductModel of productsForBin) {
        const labelXml = dymoMediumLabel(
          `Klantnr + naam: ${orderProductModel.name}`,
          `Type + jaar: ${repair.brand || ""}`,
          `Leveringsmethode: ${repair.deliveryMethod}`,
          `Baknr: ${orderProductModel.bin?.name}`,
          `Opmerking: ${repair.comment}`,
          url,
          labelDate
        );
        // Use await because DymoPrintService crashes if to many requests are send at once.
        await handlePrintSingleLabel(
          mediumLabelPrinter.availablePrinter.name,
          mediumLabelPrinter.printerModel.printerParams || null,
          labelXml,
          "medium"
        );
      }
    } else {
      ToastService.warning(`No printer available for medium labels`);
    }

    // Large labels
    const largeLabelPrinter = getFirstAvailablePrinterModel(
      labelToPrinterMap.get("large"),
      availablePrinters
    );
    if (largeLabelPrinter) {
      const labelXml = dymoLargeLabel(
        [
          `Klantnr + naam: ${repair.customer?.customerNumber || ""} ${
            repair.customer?.fullName
          }`,
          `Type + jaar: ${repair.brand || ""}`,
          `Leveringsmethode: ${repair.deliveryMethod}`,
          `Baknr: ${binId}`,
          `Opmerking: ${repair.comment}`,
          ``,
          `Inhoud onderdelen: (Reparatienummer en Onderdeel)`,
          productsForBin.map((item) => `${item.id} ${item.name}`).join("\n"),
        ].join("\n"),
        url,
        labelDate
      );
      // Use await because DymoPrintService crashes if to many requests are send at once.
      await handlePrintSingleLabel(
        largeLabelPrinter.availablePrinter.name,
        largeLabelPrinter.printerModel.printerParams || null,
        labelXml,
        "large"
      );
    } else {
      ToastService.warning(`No printer available for large labels`);
    }
  }, [repair, printers, url, binId]);
};
