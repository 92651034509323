import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const LinkedCameraIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M20 9L20 20L4 20L4 8H8.05L9.88 6L15 6V4H9L7.17 6L4 6C2.9 6 2 6.9 2 8L2 20C2 21.1 2.9 22 4 22L20 22C21.1 22 22 21.1 22 20L22 9H20ZM20.67 7.99L22 7.99C21.99 4.68 19.31 2 16 2V3.33C18.58 3.33 20.66 5.41 20.67 7.99ZM18 7.99H19.33C19.32 6.15 17.84 4.67 16 4.67V6C17.11 6 17.99 6.89 18 7.99ZM7 14C7 16.76 9.24 19 12 19C14.76 19 17 16.76 17 14C17 11.24 14.76 9 12 9C9.24 9 7 11.24 7 14ZM15 14C15 15.65 13.65 17 12 17C10.35 17 9 15.65 9 14C9 12.35 10.35 11 12 11C13.65 11 15 12.34 15 14Z"
        />
      </svg>
    )}
  </Icon>
);
