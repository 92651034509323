import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const ArrowLeftIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
        />
      </svg>
    )}
  </Icon>
);
