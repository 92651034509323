import en from "react-phone-number-input/locale/en.json";
import de from "react-phone-number-input/locale/de.json";
import fr from "react-phone-number-input/locale/fr.json";
import it from "react-phone-number-input/locale/it.json";
import nl from "react-phone-number-input/locale/nl.json";
import { CountryCode } from "libphonenumber-js/types";
import i18next from "@/i18n/config";
import {
  CUSTOMER_TYPE,
  CUSTOMER_TYPES,
  DELIVERY_METHOD,
  DELIVERY_METHODS,
  REPAIR_STATUS,
  REPAIR_STATUS_OPTIONS,
  ROLE,
  ROLES,
} from "@/resources/enums";

const localeLabels: { [lang: string]: { [key: string]: string } } = {
  en, de, fr, it, nl
};

export const getCurrentLanguage = () => {
  return localStorage.getItem('i18nextLng');
};

export const getCountryName = (code?: CountryCode) => {
  if (!code) {
    return '';
  }

  try {
    const labels = localeLabels[getCurrentLanguage() || 'en'] || localeLabels.en;
    const countryName = labels[code];
    return countryName || code;
  } catch {
    return code;
  }
};

export const getLanguageNameFromCountryCode = async (code?: CountryCode): Promise<string> => {
  if (!code) {
    return '';
  }

  try {
    const data = await fetch(`https://restcountries.com/v2/alpha/${code}`)
      .then((res) => res.json());
    return data.languages[0]?.name;
  } catch {
    return code;
  }
};

export const formatCurrency = (value: number) => {
  return value.toLocaleString(i18next.language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const parseAddress = (address: string) => {
  const [city, postcode, houseNumber, ...streets] = address.split(/,\s*|\s+/g).reverse();
  return {
    city,
    postcode,
    houseNumber,
    street: streets.reverse().join(' '),
  };
};

export const getRepairStatusText = (status: REPAIR_STATUS) => {
  return REPAIR_STATUS_OPTIONS.find((item) => item.value === status)?.text || status;
};

export const getDeliveryMethodText = (status: DELIVERY_METHOD) => {
  return DELIVERY_METHODS.find((item) => item.value === status)?.text || status;
};

export const getCustomerTypeText = (status: CUSTOMER_TYPE) => {
  return CUSTOMER_TYPES.find((item) => item.value === status)?.text || status;
};

export const getRoleText = (status: ROLE) => {
  return ROLES.find((item) => item.value === status)?.text || status;
};
