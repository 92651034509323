import { LabelSize, PrinterModelAndParms } from "./types";

export const labelToPrinterMap = new Map<LabelSize, PrinterModelAndParms[]>([
  [
    "small",
    [
      {
        printerModel: "DYMO LabelWriter 450 Twin Turbo",
        printerParams: {
          twinTurboRoll: "Right",
          printQuality: "BarcodeAndGraphics",
        },
      },
    ],
  ],
  [
    "medium",
    [
      {
        printerModel: "DYMO LabelWriter 450 Twin Turbo",
        printerParams: { twinTurboRoll: "Left" },
      },
    ],
  ],
  [
    "large",
    [
      { printerModel: "DYMO LabelWriter 4XL" },
      { printerModel: "DYMO LabelWriter 5XL" },
    ],
  ],
]);
