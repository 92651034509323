import {prismicClient} from "@/services/prismic-client";
import {store} from "@/redux/store";
import {finishLoading, startLoading} from "@/redux/reducers/spinner.reducer";
import {ProductModel} from "@/resources/models";

export class PrismicService {

  static fetchAsync(asyncFn: Promise<any>, showSpinner = true) {
    if (showSpinner) {
      store.dispatch(startLoading());
    }
    return asyncFn.finally(() => {
      if (showSpinner) {
        store.dispatch(finishLoading());
      }
    });
  }

  static searchProducts(showSpinner = true): Promise<ProductModel[]> {
    return PrismicService.fetchAsync(
      prismicClient.getAllByType('products'),
      showSpinner
    ).then((res) => (
      res.map((item: any) => new ProductModel(item.data))
    ));
  }
}
