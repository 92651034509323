import {FC, useState} from "react";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import {Menu, MenuItem} from "@mui/material";
import {FilterIcon} from "@/icons";

export type RepairFilter = 'overdue';

export interface IFilterMenuProps {
  filter?: RepairFilter;
  onChange(filter?: RepairFilter): void;
}

const options: { label: string, value: RepairFilter }[] = [
  { label: 'common.overdue', value: 'overdue' },
];

const FilterMenu: FC<IFilterMenuProps> = ({
  filter,
  onChange,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const onSelect = (selected: RepairFilter) => {
    onChange(selected === filter ? undefined : selected);
    setAnchorEl(undefined);
  };

  return (
    <>
      <button
        className={classnames('icon-btn', filter && 'active')}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <FilterIcon />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        classes={{ paper: 'w-30' }}
        onClose={() => setAnchorEl(undefined)}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={option.value === filter}
            onClick={() => onSelect(option.value)}
          >
            {t(option.label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilterMenu;
