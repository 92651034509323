import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {Chip, CustomDrawer} from "@/components";
import {IFilter} from "@/pages/Repairs/Home";

export interface ISortPanelProps {
  opened: boolean;
  filter: IFilter;
  onChange(filter: Partial<IFilter>): void;
  onClose(): void;
}

export interface IFormData {
  sortBy?: string;
  sortDir?: 'ASC' | 'DESC';
  groupBy?: string;
  groupDir?: 'ASC' | 'DESC';
}

const SortPanel: FC<ISortPanelProps> = ({
  opened,
  filter,
  onChange,
  onClose,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState<IFormData>({
    sortBy: filter.sort[0]?.field,
    sortDir: filter.sort[0]?.dir,
    groupBy: filter.group?.field,
    groupDir: filter.group?.dir,
  });

  const sortByOptions = [
    { text: t('common.delivery'), value: 'deliveryMethod' },
    { text: t('common.status'), value: 'status' },
    { text: t('common.customer'), value: 'customerId' },
    { text: t('common.caseNumber'), value: 'id' },
    { text: t('common.receiptDate'), value: 'createdAt' },
    { text: t('common.modificationDate'), value: 'updatedAt' },
    { text: t('common.closingDate'), value: 'closingDate' },
  ];

  const directionOptions = [
    { text: t('common.descending'), value: 'DESC' },
    { text: t('common.ascending'), value: 'ASC' },
  ];

  const groupByOptions = [
    { text: t('common.noGrouping'), value: null },
    { text: t('common.delivery'), value: 'deliveryMethod' },
    { text: t('common.status'), value: 'status' },
    { text: t('common.customer'), value: 'customerId' },
    { text: t('common.fileStatus'), value: 'status' },
  ];

  const onFieldChange = (field: string, value: any) => {
    const newData = {
      ...data,
      [field]: value,
    };
    setData(newData);
    onSubmit(newData);
  };

  const onSubmit = (form: IFormData) => {
    let shouldUpdate = false;
    const newFilter: Partial<IFilter> = {};
    if (form.sortBy && form.sortDir) {
      newFilter.sort = [{ field: form.sortBy, dir: form.sortDir }];
      shouldUpdate = true;
    }
    if (form.groupBy && form.groupDir) {
      newFilter.group = { field: form.groupBy, dir: form.groupDir };
      shouldUpdate = true;
    } else if (form.groupBy === null && form.groupDir) {
      newFilter.group = undefined;
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      onChange(newFilter);
    }
  };

  return (
    <CustomDrawer
      isOpen={opened}
      title={t('common.sort') + ' & ' + t('common.group').toLowerCase()}
      position="end"
      contentClass="p-8"
      onClose={onClose}
    >
      <h3 className="font-bold">{t('common.sortBy')}</h3>
      <div className="flex flex-wrap gap-2 mt-2">
        {sortByOptions.map((option, i) => (
          <Chip
            key={i}
            theme="blue-800"
            variant="outline"
            active={data.sortBy === option.value}
            clickable
            onClick={() => onFieldChange('sortBy', option.value)}
          >
            {option.text}
          </Chip>
        ))}
      </div>

      <hr className="bg-blue-200 my-8" />

      <h3 className="font-bold">{t('common.sortOrder')}</h3>
      <div className="flex flex-wrap gap-2 mt-2">
        {directionOptions.map((option, i) => (
          <Chip
            key={i}
            theme="blue-800"
            variant="outline"
            active={data.sortDir === option.value}
            clickable
            onClick={() => onFieldChange('sortDir', option.value)}
          >
            {option.text}
          </Chip>
        ))}
      </div>

      <hr className="bg-blue-200 my-8" />

      <h3 className="font-bold">{t('common.groupBy')}</h3>
      <div className="flex flex-wrap gap-2 mt-2">
        {groupByOptions.map((option, i) => (
          <Chip
            key={i}
            theme="blue-800"
            variant="outline"
            active={data.groupBy === option.value}
            clickable
            onClick={() => onFieldChange('groupBy', option.value)}
          >
            {option.text}
          </Chip>
        ))}
      </div>

      <hr className="bg-blue-200 my-8" />

      <h3 className="font-bold">{t('common.groupOrder')}</h3>
      <div className="flex flex-wrap gap-2 mt-2">
        {directionOptions.map((option, i) => (
          <Chip
            key={i}
            theme="blue-800"
            variant="outline"
            active={data.groupDir === option.value}
            clickable
            onClick={() => onFieldChange('groupDir', option.value)}
          >
            {option.text}
          </Chip>
        ))}
      </div>
    </CustomDrawer>
  );
}

export default SortPanel;
