import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataTable, IDataTableColumn, IPaginationModel, ISortModel} from "@/components";
import FullLayout from "@/layouts/FullLayout";
import EditEmployerPanel from "@/pages/Employers/EditEmployerPanel";
import {useAppSelector} from "@/redux/hooks";
import {EmployerModel, RepairModel} from "@/resources/models";
import {EmployerService, ToastService} from "@/services";
import {formatDate, getRoleText, getSortQueryString} from "@/utils/helpers";

export interface IFilter {
  search: string;
  sort: ISortModel[];
  pagination: IPaginationModel;
}

const Employers = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [filter, setFilter] = useState<IFilter>({
    search: '',
    sort: [{ field: 'fullName', dir: 'ASC' }],
    pagination: {
      page: 0,
      perPage: 20,
    },
  });
  const [totalCount, setTotalCount] = useState(0);
  const [employers, setEmployers] = useState<EmployerModel[]>([]);
  const [updatingEmployer, setUpdatingEmployer] = useState<EmployerModel>();

  const tableColumns: IDataTableColumn[] = [
    {
      title: t('common.userName') || 'User name',
      field: 'fullName',
      sortable: true,
      render(data: EmployerModel) {
        return (
          <span className="text-link" onClick={() => setUpdatingEmployer(data)}>
            {data.fullName}
          </span>
        );
      },
    },
    {
      title: t('common.role') || 'Role',
      field: 'role',
      sortable: true,
      getText(data: EmployerModel) {
        return getRoleText(data.role);
      },
    },
    {
      title: t('common.dateCreated') || 'Date created',
      field: 'createdAt',
      cellClass: 'text-blue-400',
      sortable: true,
      getText(data: RepairModel) {
        return formatDate(data.createdAt, 'full').toLowerCase();
      },
    },
    {
      field: '',
      maxWidth: '10rem',
      align: 'right',
      cellClass: 'items-center whitespace-nowrap !py-3 gap-2',
      render(data: EmployerModel) {
        if (data.id === user?.id) {
          return null;
        }
        return (
          <>
            <button className="icon-btn btn-sm" onClick={() => setUpdatingEmployer(data)}>
              <i className="fa fa-edit" />
            </button>
            <button className="icon-btn btn-sm" onClick={() => onDeleteEmployer(data)}>
              <i className="fa fa-trash" />
            </button>
          </>
        )
      }
    },
  ];

  const loadTableData = useCallback(() => {
    EmployerService.search({
      search: filter.search,
      skip: filter.pagination.page * filter.pagination.perPage,
      limit: filter.pagination.perPage,
      sort: getSortQueryString(filter.sort),
    }).then((res) => {
      setEmployers(res.data);
      setTotalCount(res.totalCount);
    });
  }, [filter]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const onFilterChange = useCallback((field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
      ...(field === 'search' && ({
        pagination: {
          ...prev.pagination,
          page: 0,
        },
      })),
    }));
  }, []);

  const onCreate = () => {
    setUpdatingEmployer(new EmployerModel());
  };

  const onDeleteEmployer = (employer: EmployerModel) => {
    if (!window.confirm(t('employees.deletionConfirmMessage') || 'Are your sure?')) {
      return;
    }
    EmployerService.remove(employer.id).then(() => {
      ToastService.success(t('employees.employerDeletedMessage'));
      loadTableData();
    }).catch((err) => {
      ToastService.showHttpError(err, t('common.operationFailedMessage'));
    });
  };

  const onCloseUpdateEmployerPanel = (result?: EmployerModel) => {
    if (result) {
      loadTableData();
    }
    setUpdatingEmployer(undefined);
  };

  return (
    <FullLayout onSearchChange={onFilterChange}>
      <div className="flex items-center justify-between flex-wrap gap-2">
        <h1 className="mr-8">{t('employees.pageTitle')}</h1>
        <button className="btn btn-blue" onClick={onCreate}>
          {t('employees.createEmployer')}
        </button>
      </div>
      <DataTable
        className="mt-4"
        theme="card-row"
        columns={tableColumns}
        data={employers}
        serverSide
        sortModel={filter.sort}
        pagination="auto"
        paginationModel={filter.pagination}
        totalCount={totalCount}
        onSort={(model) => onFilterChange('sort', model)}
        onPaginate={(model) => onFilterChange('pagination', model)}
      />

      <EditEmployerPanel
        opened={Boolean(updatingEmployer)}
        employer={updatingEmployer}
        onClose={onCloseUpdateEmployerPanel}
      />
    </FullLayout>
  );
};
export default Employers;
