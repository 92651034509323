import i18next from "@/i18n/config";
import {ORDER_LOG_ACTION} from "../enums";
import {RepairModel} from "@/resources/models/repair.model";
import {EmployerModel} from "@/resources/models/employer.model";

export class OrderLogModel {
  id: number;
  orderId: string;
  action: ORDER_LOG_ACTION;
  description: string;
  worktable: string | null;
  employerId: number | null;
  createdAt: string;
  updatedAt: string;
  order?: RepairModel;
  employer?: EmployerModel;

  constructor(init?: Partial<OrderLogModel>) {
    const data = {
      id: 0,
      orderId: '',
      action: ORDER_LOG_ACTION.CUSTOMER_REGISTRATION,
      description: '',
      worktable: null,
      employerId: null,
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    this.orderId = data.orderId;
    this.action = data.action;
    this.description = data.description;
    this.worktable = data.worktable;
    this.employerId = data.employerId;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;

    if (data.order) {
      this.order = new RepairModel(data.order);
    }
    if (data.employer) {
      this.employer = new EmployerModel(data.employer);
    }
  }

  getActionText() {
    return i18next.t(`common.orderLogActions.${this.action}`) || this.action;
  }

  getDescription() {
    switch (this.action) {
      case ORDER_LOG_ACTION.CUSTOMER_REGISTRATION:
        return '';
    }

    return this.description || i18next.t(`common.orderLogActions.${this.action}`) || this.action;
  }

  getEmployerName() {
    if (!this.employerId) {
      return 'Super Admin'
    }
    if (this.employer) {
      return this.employer.fullName;
    }
    if (this.employerId) {
      return `employer-${this.employerId}`;
    }
    return '';
  }
}
