import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "@/components";
import AuthLayout from "@/layouts/AuthLayout";
import {useAppDispatch} from "@/redux/hooks";
import {resetPassword} from "@/redux/reducers/auth.reducer";
import {AuthService, ToastService} from "@/services";

export interface IFormData {
  password: string;
  confirmPassword: string;
}

const ResetConfirmPassword = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const validationError = {
    required: t('common.validation.required'),
    passwordMatch: t('common.validation.passwordMatch'),
  };

  const formSchema = Yup.object().shape({
    password: Yup.string().required(validationError.required),
    confirmPassword: Yup.string().required(validationError.required)
      .oneOf([Yup.ref('password')], validationError.passwordMatch),
  });

  const formik = useFormik<IFormData>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: formSchema,
    onSubmit(values) {
      AuthService.resetEmployerPassword({
        password: values.password,
      }).then(() => {
        dispatch(resetPassword());
      }).catch((err) => {
        ToastService.showHttpError(err, t('auth.resetPasswordFailedMessage'));
      });
    },
  });

  return (
    <AuthLayout>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-4">
          <h1 className="text-center">{t('auth.resetPassword')}</h1>
        </div>
        <div className="p-4">
          <TextInput
            formik={formik}
            name="password"
            type="password"
            fullWidth
            label={t('common.password')}
            size="small"
          />

          <TextInput
            className="!mt-4"
            formik={formik}
            name="confirmPassword"
            type="password"
            fullWidth
            label={t('common.confirmPassword')}
            size="small"
          />

          <button
            className="btn btn-blue w-full mt-6"
            type="submit"
          >
            {t('auth.resetPassword')}
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};
export default ResetConfirmPassword;
