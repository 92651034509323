import {HttpService} from "./http.service";
import {LoginDto, LoginResponseDto, ResetEmployerPasswordDto} from "@/resources/dtos";
import {IVatNumberInfo} from "@/resources/interfaces";
import {EmployerModel} from "@/resources/models";
import {store} from "@/redux/store";
import {setToken} from "@/redux/reducers/auth.reducer";

export class AuthService {

  static login(data: LoginDto, showSpinner = true): Promise<LoginResponseDto> {
    return HttpService.post('/auth/login/employer', data, showSpinner);
  }

  static resetEmployerPassword(data: ResetEmployerPasswordDto, showSpinner = true) {
    return HttpService.post('/auth/reset-employer-password', data, showSpinner);
  }

  static me(showSpinner = true): Promise<EmployerModel> {
    return HttpService.get('/auth/me', {}, showSpinner)
      .then((res) => new EmployerModel(res));
  }

  static logout() {
    store.dispatch(setToken(null));
  }

  static validateVatNumber(vatNumber: string, showSpinner = true): Promise<IVatNumberInfo> {
    return HttpService.post('/auth/verify-vat-number', { vatNumber }, showSpinner);
  }
}
