import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Chip, ChipTheme, DataTable, IDataTableColumn, IPaginationModel, ISortModel } from "@/components";
import FullLayout from "@/layouts/FullLayout";
import Filter from "@/pages/Customers/Home/Filter";
import { CUSTOMER_TYPE, CUSTOMER_TYPES } from "@/resources/enums";
import { CustomerModel } from "@/resources/models";
import { CustomerService } from "@/services";
import { formatDate, getCountryName, getSortQueryString } from "@/utils/helpers";

export interface IFilter {
  search: string;
  type?: CUSTOMER_TYPE;
  vip?: boolean;
  sort: ISortModel[];
  pagination: IPaginationModel;
}

const Customers = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<IFilter>({
    search: '',
    sort: [{ field: 'firstName,lastName', dir: 'ASC' }],
    pagination: {
      page: 0,
      perPage: 20,
    },
  });
  const [totalCount, setTotalCount] = useState(0);
  const [customers, setCustomers] = useState<CustomerModel[]>([]);

  const tableColumns: IDataTableColumn[] = [
    {
      title: t('common.name') || 'Name',
      field: 'firstName,lastName',
      sortable: true,
      render(data: CustomerModel) {
        return (
          <Link className="hover:underline" to={`/customers/${data.id}`}>
            {data.fullName}
          </Link>
        );
      },
    },
    { title: t('common.email') || 'Email', field: 'email', sortable: true },
    { title: t('common.telephone') || 'Phone', field: 'phone', sortable: true },
    {
      title: t('common.type') || 'Type',
      field: 'type',
      sortable: true,
      cellClass: '!items-center !py-2',
      render(data: CustomerModel) {
        const customerType = CUSTOMER_TYPES.find((item) => item.value === data.type);
        return (
          <Chip theme={(customerType?.color || 'secondary') as ChipTheme} size="sm">{customerType?.text || data.type}</Chip>
        );
      },
    },
    { title: t('common.company') || 'Company', field: 'companyName', sortable: true },
    { title: t('common.vatNumber') || 'VAT number', field: 'vatNumber', sortable: true },
    {
      title: t('common.country') || 'Country',
      field: 'country',
      sortable: true,
      getText(data: CustomerModel) {
        return getCountryName(data.country);
      }
    },
    {
      title: t('common.vip') || 'VIP',
      field: 'vip',
      sortable: true,
      render(data: CustomerModel) {
        if (data.vip) {
          return (
            <div>
              <Chip theme="danger" size="sm">{t('common.vip')}</Chip>
            </div>
          );
        }
        return null;
      }
    },
    {
      title: t('common.registerDate') || 'Register date',
      field: 'createdAt',
      cellClass: 'text-blue-400',
      sortable: true,
      getText(data: CustomerModel) {
        return formatDate(data.createdAt, 'full').toLowerCase();
      },
    },
  ];

  const loadTableData = useCallback(() => {
    CustomerService.search({
      search: filter.search,
      type: filter.type,
      vip: filter.vip,
      skip: filter.pagination.page * filter.pagination.perPage,
      limit: filter.pagination.perPage,
      sort: getSortQueryString(filter.sort),
    }).then((res) => {
      setCustomers(res.data);
      setTotalCount(res.totalCount);
    });
  }, [filter]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const onFilterChange = useCallback((field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
      ...(field === 'search' && ({
        pagination: {
          ...prev.pagination,
          page: 0,
        },
      })),
    }));
  }, []);

  const debouncedChangeFilter = debounce(onFilterChange, 500);

  const onUpdateFilter = (updates: Partial<IFilter>) => {
    setFilter((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  return (
    <FullLayout onSearchChange={(value) => debouncedChangeFilter('search', value)}>
      <div className="flex items-center justify-between flex-wrap gap-2">
        <h1 className="mr-8">{t('customers.pageTitle')}</h1>
        <Filter filter={filter} onChange={onUpdateFilter} />
      </div>

      <DataTable
        className="mt-4"
        theme="card-row"
        columns={tableColumns}
        data={customers}
        serverSide
        sortModel={filter.sort}
        pagination="auto"
        paginationModel={filter.pagination}
        totalCount={totalCount}
        onSort={(model) => onFilterChange('sort', model)}
        onPaginate={(model) => onFilterChange('pagination', model)}
      />
    </FullLayout>
  );
};
export default Customers;
