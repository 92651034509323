import {HttpService} from "./http.service";
import {
  AddOrderProductsRequestDto,
  CompleteOrderStatusRequestDto,
  CountOrdersRequestDto,
  SearchOrdersRequestDto,
  UpdateOrderProductsRequestDto,
  UpdateOrderRequestDto
} from "@/resources/dtos";
import {OrderProductModel, RepairModel} from "@/resources/models";
import {formatPaginatedResult, trimObject} from "@/utils/helpers";

export class RepairService {

  static search(data: SearchOrdersRequestDto, showSpinner = true) {
    return HttpService.get('/orders', trimObject(data), showSpinner)
      .then((res) => formatPaginatedResult<RepairModel>(RepairModel, res));
  }

  static find(id: string, showSpinner = true) {
    return HttpService.get(`/orders/${id}`, {}, showSpinner)
      .then((res) => new RepairModel(res));
  }

  static count(query: CountOrdersRequestDto, showSpinner = true) {
    return HttpService.get('/orders/count', query, showSpinner);
  }

  static completeStatus(id: string, data: CompleteOrderStatusRequestDto, showSpinner = true) {
    return HttpService.post(`/orders/${id}/next-status`, data, showSpinner)
      .then((res) => new RepairModel(res));
  }

  static patch(id: string, data: UpdateOrderRequestDto, showSpinner = true) {
    return HttpService.patch(`/orders/${id}`, trimObject(data, [undefined]), showSpinner)
      .then((res) => new RepairModel(res));
  }

  static addProducts(id: string, data: AddOrderProductsRequestDto, showSpinner = true) {
    return HttpService.post(`/orders/${id}/products`, data, showSpinner)
      .then((res) => res.map((item: any) => new OrderProductModel(item)));
  }

  static updateProducts(id: string, data: UpdateOrderProductsRequestDto, showSpinner = true) {
    return HttpService.put(`/orders/${id}/products`, data, showSpinner)
      .then((res) => res.map((item: any) => new OrderProductModel(item)));
  }
}
