import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const ArrowUpIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
        />
      </svg>
    )}
  </Icon>
);
