import React, {useEffect, useState} from "react";
import classnames from "classnames";
import ReactPaginate from "react-paginate";
import {ArrowLeftIcon, ArrowRightIcon} from "@/icons";
import "./style.scss";

export interface IPaginatorProps {
  className?: string;
  page: number;
  pageCount: number;
  onPageChange?: (selectedPage: number) => void;
}

export const Paginator: React.FC<IPaginatorProps> = ({
  className,
  page,
  pageCount = 50,
  onPageChange,
}) => {
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handlePageClick = (data: { selected: number }) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    if (onPageChange) {
      onPageChange(selectedPage);
    }
  };

  const pageButtonClass = classnames(
    'min-w-12 h-12 flex-center bg-white border border-secondary rounded-full',
    'hover:bg-secondary font-semibold select-none'
  );

  return (
    <ReactPaginate
      className={classnames('paginator flex-center text-blue gap-x-2', className)}
      forcePage={currentPage}
      previousLabel={<ArrowLeftIcon />}
      nextLabel={<ArrowRightIcon />}
      breakLabel="..."
      breakClassName="mx-2 select-none"
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      pageLinkClassName={classnames(pageButtonClass, 'px-4 py-2')}
      nextClassName={pageButtonClass}
      previousLinkClassName={pageButtonClass}
      activeLinkClassName="!bg-blue-800 !border-blue-800 text-white select-none"
      onPageChange={handlePageClick}
    />
  );
};
