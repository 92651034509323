import React, {ChangeEventHandler, FC, ReactNode} from "react";
import {FormikProps} from "formik";
import {MenuItem, TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";

export type SelectProps = {
  className?: string;
  name: string;
  formik?: FormikProps<any>;
  options?: any[];
  getOptionValue?: (option: any) => any;
  getOptionLabel?: (option: any) => ReactNode;
  onChange?: (field: string, value: any) => void;
  children?: ReactNode;
} & Omit<TextFieldProps, 'onChange'>;

export const Select: FC<SelectProps> = ({
  name,
  formik,
  options,
  getOptionValue,
  getOptionLabel,
  onChange,
  children,
  ...props
}) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (formik) {
      formik.handleChange(e);
    }
    if (onChange) {
      onChange(name, e.target.value);
    }
  };

  return (
    <TextField
      name={name}
      select
      {...props}
      {...formik && (
        {
          value: formik.values[name] || '',
          error: formik.touched[name] && Boolean(formik.errors[name]),
          helperText: formik.touched[name] ? (formik.errors[name] as string) : '',
          onBlur: formik.handleBlur,
        }
      )}
      onChange={onInputChange}
    >
      {children || (options || []).map((option, i) => {
        const label = getOptionLabel ? getOptionLabel(option) : option;
        const value = getOptionValue ? getOptionValue(option) : option;
        return (
          <MenuItem key={i} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
