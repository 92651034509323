import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Chip, ChipTheme, DataTable, IDataTableColumn, IPaginationModel, ISortModel, SearchBox} from "@/components";
import {DELIVERY_METHOD, DELIVERY_METHODS, REPAIR_STATUS} from "@/resources/enums";
import {CustomerModel, RepairModel} from "@/resources/models";
import {RepairService} from "@/services";
import {formatDate, getRepairStatusText, getSortQueryString} from "@/utils/helpers";
import {DeliveryMethodSelect, OrderStatusSelect} from "@/sections";
import {Link} from "react-router-dom";

export interface IOrdersTableProps {
  customer: CustomerModel;
}

export interface IFilter {
  search: string;
  deliveryMethod: DELIVERY_METHOD[];
  status: REPAIR_STATUS[];
  sort: ISortModel[];
  pagination: IPaginationModel;
}

const OrdersTable: FC<IOrdersTableProps> = ({
  customer,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<IFilter>({
    search: '',
    deliveryMethod: [],
    status: [],
    sort: [{ field: 'createdAt', dir: 'DESC' }],
    pagination: {
      page: 0,
      perPage: 20,
    },
  });
  const [totalCount, setTotalCount] = useState(0);
  const [orders, setOrders] = useState<RepairModel[]>([]);

  const countQuery = useMemo(() => ({
    customerId: customer.id,
  }), [customer.id]);

  const tableColumns: IDataTableColumn[] = [
    {
      title: t('repairs.tableColumns.repairNumber') || 'Repair number',
      field: 'id',
      sortable: true,
      render(data: RepairModel) {
        return (
          <Link className="hover:underline" to={`/repairs/${data.id}`}>
            {data.id}
          </Link>
        );
      },
    },
    {
      title: t('repairs.tableColumns.deliveryMethod') || 'Delivery method',
      field: 'deliveryMethod',
      sortable: true,
      cellClass: '!py-3',
      render(data) {
        const deliveryMethod = DELIVERY_METHODS.find((item) => item.value === data.deliveryMethod);
        return (
          <div className="flex items-center flex-wrap gap-1">
            {data.deliveryMethod && (
              <Chip theme={(deliveryMethod?.color || 'secondary') as ChipTheme} size="sm">{deliveryMethod?.text || data.deliveryMethod}</Chip>
            )}
            {data.urgent && (
              <Chip theme="danger" size="sm">{t('common.urgent')}</Chip>
            )}
          </div>
        );
      },
    },
    {
      title: t('repairs.tableColumns.status') || 'Status',
      field: 'status',
      sortable: true,
      getText(data: RepairModel) {
        return getRepairStatusText(data.status);
      },
    },
    {
      title: t('repairs.tableColumns.receiptDate') || 'Receipt date',
      field: 'createdAt',
      cellClass: 'text-blue-400',
      sortable: true,
      getText(data: RepairModel) {
        return formatDate(data.createdAt, 'full').toLowerCase();
      },
    },
  ];

  const loadTableData = useCallback(() => {
    RepairService.search({
      customerId: customer.id,
      search: filter.search,
      deliveryMethod: filter.deliveryMethod.length ? filter.deliveryMethod : undefined,
      status: filter.status.length ? filter.status : undefined,
      skip: filter.pagination.page * filter.pagination.perPage,
      limit: filter.pagination.perPage,
      sort: getSortQueryString(filter.sort),
    }).then((res) => {
      setOrders(res.data);
      setTotalCount(res.totalCount);
    });
  }, [customer.id, filter]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const onFilterChange = useCallback((field: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [field]: value,
      ...(field === 'search' && ({
        pagination: {
          ...prev.pagination,
          page: 0,
        },
      })),
    }));
  }, []);

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4">
        <h2 className="mr-auto">{t('repairs.pageTitle')}</h2>

        <div className="w-80 shrink">
          <SearchBox
            placeholder={t('common.search') || ''}
            onChange={onFilterChange}
          />
        </div>

        <DeliveryMethodSelect
          value={filter.deliveryMethod}
          query={countQuery}
          onChange={(value) => onFilterChange('deliveryMethod', value)}
        />

        <OrderStatusSelect
          value={filter.status}
          query={countQuery}
          onChange={(value) => onFilterChange('status', value)}
        />
      </div>

      <DataTable
        className="mt-4"
        columns={tableColumns}
        data={orders}
        cellClass="items-center !px-2 !py-3"
        headerCellClass="!px-2 !py-3"
        serverSide
        sortModel={filter.sort}
        pagination="auto"
        paginationModel={filter.pagination}
        totalCount={totalCount}
        onSort={(model) => onFilterChange('sort', model)}
        onPaginate={(model) => onFilterChange('pagination', model)}
      />
    </div>
  );
};
export default OrdersTable;
