import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const FilterIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M6.99999 6L17 6L11.99 12.3L6.99999 6ZM4.24999 5.61C6.26999 8.2 9.99999 13 9.99999 13L9.99999 19C9.99999 19.55 10.45 20 11 20H13C13.55 20 14 19.55 14 19V13C14 13 17.72 8.2 19.74 5.61C20.25 4.95 19.78 4 18.95 4L5.03999 4C4.20999 4 3.73999 4.95 4.24999 5.61Z"
        />
      </svg>
    )}
  </Icon>
);
