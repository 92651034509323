import {HttpService} from "./http.service";
import {SettingsModel} from "@/resources/models";
import {trimObject} from "@/utils/helpers";

export class SettingsService {

  static get(showSpinner = true) {
    return HttpService.get(`/settings`, {}, showSpinner)
      .then((res) => new SettingsModel(res));
  }

  static patch(data: Partial<SettingsModel>, showSpinner = true) {
    return HttpService.patch(`/settings`, trimObject(data), showSpinner)
      .then((res) => new SettingsModel(res));
  }
}
