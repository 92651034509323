import React, {FC, useEffect} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {CustomDrawer, Select, TextInput} from "@/components";
import {BIN_CONTENTS} from "@/constants";
import {BinModel} from "@/resources/models";
import {BinService, ToastService} from "@/services";
import {BIN_POSITION, BIN_POSITIONS} from "@/resources/enums";

export interface IEditBinPanelProps {
  opened: boolean;
  bin?: BinModel;
  onClose(result?: BinModel): void;
}

export interface IFormData {
  name: string;
  category: string;
  position: BIN_POSITION;
  content: number;
}

const categories = ['BAL', 'CA13', 'CA12', 'CA24', 'CA23', 'CA22', 'CA34', 'CA33', 'CA32', 'DA14', 'DA13', 'DA12'];

const EditBinPanel: FC<IEditBinPanelProps> = ({
  opened,
  bin,
  onClose,
}) => {
  const { t } = useTranslation();

  const validationError = {
    required: t('common.validation.required'),
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required(validationError.required),
    category: Yup.string().required(validationError.required),
    position: Yup.string().required(validationError.required),
    content: Yup.number().required(validationError.required),
  });

  const isCreate = !bin?.id;

  const formik = useFormik<IFormData>({
    initialValues: {
      name: bin?.name || '',
      category: bin?.category || '',
      position: bin?.position || BIN_POSITION.UPPER_BACKSIDE,
      content: bin?.content || BIN_CONTENTS[0].content,
    },
    validationSchema: formSchema,
    onSubmit(values) {
      const formData = {
        name: values.name.trim(),
        category: values.category,
        position: values.position,
        content: values.content,
      };
      if (isCreate) {
        BinService.create(formData).then((res) => {
          ToastService.success(t('bins.binCreatedMessage'));
          onClose(res);
        }).catch((err) => {
          ToastService.showHttpError(err, t('bins.creationFailedMessage'));
        });
      } else {
        BinService.update(bin!.id, formData).then((res) => {
          ToastService.success(t('common.changesSavedMessage'));
          onClose(res);
        }).catch((err) => {
          ToastService.showHttpError(err, t('common.changesSaveFailedMessage'));
        });
      }
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        name: bin?.name || '',
        category: bin?.category || '',
        position: bin?.position || BIN_POSITION.UPPER_BACKSIDE,
        content: bin?.content || BIN_CONTENTS[0].content,
      },
    });
  }, [bin]);

  return (
    <CustomDrawer
      isOpen={opened}
      title={t(isCreate ? 'bins.createBin' : 'bins.updateBin') || ''}
      position="end"
      contentClass="p-8"
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col items-end gap-6">
          <TextInput
            formik={formik}
            name="name"
            fullWidth
            label={t('common.name')}
            size="small"
          />

          <Select
            formik={formik}
            name="category"
            fullWidth
            label={t('common.category')}
            size="small"
            options={categories}
          />

          <Select
            formik={formik}
            name="position"
            fullWidth
            label={t('common.position')}
            size="small"
            options={BIN_POSITIONS}
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.value}
          />

          <Select
            formik={formik}
            name="content"
            fullWidth
            label={t('common.content')}
            size="small"
            options={BIN_CONTENTS}
            getOptionLabel={(option) => `${option.content}L`}
            getOptionValue={(option) => option.content}
          />

          <button
            className="btn btn-blue"
            type="submit"
          >
            {t(isCreate ? 'common.create' : 'common.save')}
          </button>
        </div>
      </form>
    </CustomDrawer>
  );
}

export default EditBinPanel;
