import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {useFormik} from "formik";
import {TextInput} from "@/components";
import AuthLayout from "@/layouts/AuthLayout";
import {LoginDto} from "@/resources/dtos";
import {useAppDispatch} from "@/redux/hooks";
import {setToken} from "@/redux/reducers/auth.reducer";
import {AuthService, ToastService} from "@/services";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const validationError = {
    required: t('common.validation.required'),
  };

  const formSchema = Yup.object().shape({
    username: Yup.string().required(validationError.required),
    password: Yup.string().required(validationError.required),
  });

  const formik = useFormik<LoginDto>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: formSchema,
    onSubmit(values) {
      AuthService.login({
        username: values.username,
        password: values.password,
      }).then((res) => {
        dispatch(setToken(res.token));
      }).catch((err) => {
        ToastService.showHttpError(err, t('auth.loginFailedMessage'));
      });
    },
  });

  return (
    <AuthLayout>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-4">
          <h1 className="text-center">{t('auth.login')}</h1>
        </div>
        <div className="p-4">
          <TextInput
            formik={formik}
            name="username"
            fullWidth
            label={t('common.userName')}
            size="small"
          />

          <TextInput
            formik={formik}
            name="password"
            className="!mt-4"
            type="password"
            fullWidth
            label={t('common.password')}
            size="small"
          />

          <button
            className="btn btn-blue w-full mt-6"
            type="submit"
          >
            {t('auth.login')}
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};
export default Login;
