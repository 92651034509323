import {BIN_POSITION} from "../enums";

export class BinModel {
  id: number;
  name: string;
  category: string;
  position: BIN_POSITION;
  content: number;
  orderId?: string;
  reserved: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<BinModel>) {
    const data = {
      id: 0,
      name: '',
      category: '',
      position: BIN_POSITION.UPPER_BACKSIDE,
      content: 0,
      reserved: false,
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    this.name = data.name;
    this.category = data.category;
    this.position = data.position;
    this.content = data.content;
    this.orderId = data.orderId;
    this.reserved = data.reserved;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
