import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CONFIG } from "@/constants";
import { EmployerModel } from "@/resources/models";

export interface IAuthState {
	token?: string | null;
	user?: EmployerModel;
}

const initialState: IAuthState = {
	token: undefined,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string | null>) => {
			state.token = action.payload;
			if (state.token) {
				localStorage.setItem(CONFIG.TOKEN_KEY, state.token);
			} else {
				localStorage.removeItem(CONFIG.TOKEN_KEY);
			}
		},
		setUser: (state, action: PayloadAction<EmployerModel | undefined>) => {
			state.user = action.payload;
		},
		resetPassword: (state) => {
			state.user = new EmployerModel({
				...state.user,
				isFirstLogin: false,
			});
		},
	},
});

export const { setToken, setUser, resetPassword } = authSlice.actions;

export default authSlice.reducer;
