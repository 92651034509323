import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const ArrowRightIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M4 13L16.17 13L10.58 18.59L12 20L20 12L12 4L10.59 5.41L16.17 11L4 11L4 13Z"
        />
      </svg>
    )}
  </Icon>
);
