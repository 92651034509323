import i18next from "@/i18n/config";

export enum REPAIR_STATUS {
  REGISTRATION = 'REGISTRATION',
  UNPACKING = 'UNPACKING',
  BOOKING = 'BOOKING',
  WORKTABLE = 'WORKTABLE',
  INSPECTION = 'INSPECTION',
  READY = 'READY',
  CLOSED = 'CLOSED',
}

export const REPAIR_STATUS_VALUES = Object.values(REPAIR_STATUS);

export const REPAIR_STATUS_OPTIONS = REPAIR_STATUS_VALUES.map((item) => ({
  text: i18next.t(`common.repairStatuses.${item.toLowerCase()}`),
  value: item,
}));
