import i18next from "@/i18n/config";

export enum CUSTOMER_TYPE {
  PRIVATE = 'private',
  COMPANY = 'company',
}

export const CUSTOMER_TYPE_VALUES = Object.values(CUSTOMER_TYPE);

export const CUSTOMER_TYPES = [
  {
    text: i18next.t(`common.customerTypes.${CUSTOMER_TYPE.PRIVATE}`),
    value: CUSTOMER_TYPE.PRIVATE,
    color: 'secondary',
  },
  {
    text: i18next.t(`common.customerTypes.${CUSTOMER_TYPE.COMPANY}`),
    value: CUSTOMER_TYPE.COMPANY,
    color: 'success',
  },
];

