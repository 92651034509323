import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import {REPAIR_STATUS} from "@/resources/enums";

export interface IRepairState {
	selectedStatus?: REPAIR_STATUS;
}

const initialState: IRepairState = {
	selectedStatus: undefined,
};

export const repairSlice = createSlice({
	name: 'repair',
	initialState,
	reducers: {
		setRepairStatusFilter: (state, action: PayloadAction<REPAIR_STATUS | undefined>) => {
			state.selectedStatus = action.payload;
		},
	},
});

export const { setRepairStatusFilter } = repairSlice.actions;

export default repairSlice.reducer;
