import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCurrentLanguage } from "@/utils/helpers";

export interface ILocaleState {
	locale: string;
}

const initialState: ILocaleState = {
	locale: getCurrentLanguage() || 'nl',
};

export const localeSlice = createSlice({
	name: 'locale',
	initialState,
	reducers: {
		setLocale: (state, action: PayloadAction<string>) => {
			state.locale = action.payload;
		},
	},
});

export const { setLocale } = localeSlice.actions;

export default localeSlice.reducer;
