import React, {FC} from "react";
import {IIconProps} from "./types";
import {Icon} from "./Icon";

export const AlertIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M12 2.25c-2.604 0-5.053 1.014-6.894 2.856s-2.856 4.29-2.856 6.894c0 2.604 1.014 5.053 2.856 6.894s4.29 2.856 6.894 2.856c2.604 0 5.053-1.014 6.894-2.856s2.856-4.29 2.856-6.894c0-2.604-1.014-5.053-2.856-6.894s-4.29-2.856-6.894-2.856zM12 0v0c6.627 0 12 5.373 12 12s-5.373 12-12 12c-6.627 0-12-5.373-12-12s5.373-12 12-12zM10.5 16.5h3v3h-3zM10.5 4.5h3v9h-3z"
        />
      </svg>
    )}
  </Icon>
);
