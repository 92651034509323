import i18next from "@/i18n/config";

export enum ORDER_TYPE {
  LOOSE_PIECE_ORIGINAL = 'LOOSE_PIECE_ORIGINAL',
  SET_IMPORT_WITH_RETURN = 'SET_IMPORT_WITH_RETURN',
  SET_EXPORT_WITHOUT_RETURN = 'SET_EXPORT_WITHOUT_RETURN',
}

export const ORDER_TYPE_VALUES = Object.values(ORDER_TYPE);

export const ORDER_TYPES = ORDER_TYPE_VALUES.map((item) => ({
  text: i18next.t(`common.orderTypes.${item}`),
  value: item,
}));
