import { configureStore } from "@reduxjs/toolkit";
import authReducer, {IAuthState} from "./reducers/auth.reducer";
import localeReducer, {ILocaleState} from "./reducers/locale.reducer";
import repairReducer, {IRepairState} from "@/redux/reducers/repair.reducer";
import spinnerReducer, {ISpinnerState} from "./reducers/spinner.reducer";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		locale: localeReducer,
		repair: repairReducer,
		spinner: spinnerReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}),
});

export type IRootState = {
	auth: IAuthState,
	locale: ILocaleState,
	spinner: ISpinnerState,
	repair: IRepairState,
};

export type AppDispatch = typeof store.dispatch;
