import {ROLE} from "../enums";

export class EmployerModel {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  role: ROLE;
  isFirstLogin: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<EmployerModel>) {
    const data = {
      id: 0,
      firstName: '',
      lastName: '',
      fullName: '',
      role: ROLE.EMPLOYER,
      isFirstLogin: false,
      createdAt: '',
      updatedAt: '',
      ...init,
    };

    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.fullName = data.fullName;
    this.role = data.role;
    this.isFirstLogin = data.isFirstLogin;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  hasPermission(roles: ROLE | ROLE[] | undefined) {
    if (!roles) {
      return true;
    }
    if (!Array.isArray(roles)) {
      roles = [roles];
    }
    return roles.includes(this.role);
  }
}
