import {HttpService} from "./http.service";
import {CountCustomersRequestDto, SearchCustomersRequestDto, UpdateCustomerRequestDto} from "@/resources/dtos";
import {CustomerModel} from "@/resources/models";
import {formatPaginatedResult, trimObject} from "@/utils/helpers";

export class CustomerService {

  static search(data: SearchCustomersRequestDto, showSpinner = true) {
    return HttpService.get('/customers', trimObject(data), showSpinner)
      .then((res) => formatPaginatedResult<CustomerModel>(CustomerModel, res));
  }

  static find(id: string, showSpinner = true) {
    return HttpService.get(`/customers/${id}`, {}, showSpinner)
      .then((res) => new CustomerModel(res));
  }

  static count(query: CountCustomersRequestDto, showSpinner = true) {
    return HttpService.get('/customers/count', query, showSpinner);
  }

  static patch(id: number, data: UpdateCustomerRequestDto, showSpinner = true) {
    return HttpService.patch(`/customers/${id}`, trimObject(data, [undefined]), showSpinner)
      .then((res) => new CustomerModel(res));
  }
}
