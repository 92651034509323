import React, {ChangeEventHandler, FC} from "react";
import {FormikProps} from "formik";
import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";

export type TextInputProps = {
  className?: string;
  name: string;
  formik?: FormikProps<any>;
  onChange?: (field: string, value: string) => void;
} & Omit<TextFieldProps, 'onChange'>;

export const TextInput: FC<TextInputProps> = ({
  name,
  formik,
  onChange,
  ...props
}) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (formik) {
      formik.handleChange(e);
    }
    if (onChange) {
      onChange(name, e.target.value);
    }
  };

  return (
    <TextField
      name={name}
      {...props}
      {...formik && (
        {
          value: formik.values[name] || '',
          error: formik.touched[name] && Boolean(formik.errors[name]),
          helperText: formik.touched[name] ? (formik.errors[name] as string) : '',
          onBlur: formik.handleBlur,
        }
      )}
      onChange={onInputChange}
    />
  );
};
