import {Route, Routes} from "react-router-dom";
import Employers from "./Home";

const EmployersRouting = () => (
  <Routes>
    <Route index element={<Employers />} />
  </Routes>
);

export default EmployersRouting;
