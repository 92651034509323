import i18next from "@/i18n/config";

export enum BIN_POSITION {
  UPPER_BACKSIDE = 'UPPER-BACKSIDE',
  UPPER_FRONTSIDE = 'UPPER-FRONTSIDE',
  LOWER_BACKSIDE = 'LOWER-BACKSIDE',
  LOWER_FRONTSIDE = 'LOWER-FRONTSIDE',
}

export const BIN_POSITION_VALUES = Object.values(BIN_POSITION);

export const BIN_POSITIONS = [
  {
    text: i18next.t(`common.binPositions.${BIN_POSITION.UPPER_BACKSIDE}`),
    value: BIN_POSITION.UPPER_BACKSIDE,
  },
  {
    text: i18next.t(`common.binPositions.${BIN_POSITION.UPPER_FRONTSIDE}`),
    value: BIN_POSITION.UPPER_FRONTSIDE,
  },
  {
    text: i18next.t(`common.binPositions.${BIN_POSITION.LOWER_BACKSIDE}`),
    value: BIN_POSITION.LOWER_BACKSIDE,
  },
  {
    text: i18next.t(`common.binPositions.${BIN_POSITION.LOWER_FRONTSIDE}`),
    value: BIN_POSITION.LOWER_FRONTSIDE,
  },
];
