import {Route, Routes} from "react-router-dom";
import Customers from "./Home";
import CustomerDetail from "./Detail";

const CustomersRouting = () => (
  <Routes>
    <Route index element={<Customers />} />
    <Route path=":id" element={<CustomerDetail />} />
  </Routes>
);

export default CustomersRouting;
