import moment from "moment";
import "moment/locale/nl-be";

export const formatDate = (date: string | Date | number, format = 'd MMM. yyyy HH:mm') => {
  if (!date) {
    return '';
  }

  switch (format) {
    case 'full':
      format = 'D MMM yyyy HH:mm';
      break;
    case 'short':
      format = 'DD-MM-YYYY HH:mm';
      break;
    case 'label':
      format = 'dddd, D MMMM YYYY HH:mm';
      break;
  }

  return moment(date).format(format);
};

// Set Momentjs locale globally
export const dateTimelocaleSetup = () => {
  moment.locale('nl-be');
}
